

export const ENDPOINTS_COURSES = {
    GET_ALL: '/courses',
    GET_ALL_ADMIN: '/courses/admin',
    GET_DETAILS_BY_ID: (courseId: number) => `/courses/${courseId}/details`,
    GET_DETAILS_BY_IDLESSON: (lessonId: number) => `/courses/lesson/${lessonId}/details`,
    POST_COURSE: `/courses`,
    PUT_COURSE: (courseId: number) => `/courses/${courseId}`,
    UPLOAD_IMAGE: (courseId: number) => `/courses/${courseId}/upload-image`,
};

export const ENDPOINTS_CHAPTERS = {
    GET_ALL: '/chapters',
    GET_BY_ID: (epicId: number) => `/chapters/${epicId}`,
    POST_CHAPTER: '/chapters',
    PUT_CHAPTER: (epicId: number) => `/chapters/${epicId}`,
    DELETE_CHAPTER: (epicId: number) => `/chapters/${epicId}`,
    GET_CHAPTERS_BY_COURSE_ID: (chapterId: number) => `/courses/${chapterId}/chapters`,
};

export const ENDPOINTS_EPICS = {
    GET_ALL: '/epics',
    GET_BY_ID: (epicId: number) => `/epics/${epicId}`,
    POST_EPIC: '/epics',
    PUT_EPIC: (epicId: number) => `/epics/${epicId}`,
    DELETE_EPIC: (epicId: number) => `/epics/${epicId}`,
    GET_EPICS_BY_CHAPTER_ID: (chapterId: number) => `/chapters/${chapterId}/epics`,
};

export const ENDPOINTS_LESSONS = {
    GET_ALL: '/lessons',
    GET_BY_ID: (lessonId: number) => `/lessons/${lessonId}`,
    GET_DETAILS_BY_ID: (lessonId: number) => `/lessons/${lessonId}/details`,
    POST_SAVE_LESSON_DETAILS: `/lessons/details`,
    POST_LESSON: '/lessons',
    PUT_LESSON: (lessonId: number) => `/lessons/${lessonId}`,
    POST_DUPLICATE: (lessonId: number) => `/lessons/${lessonId}/duplicate`,
    DELETE_LESSON: (lessonId: number) => `/lessons/${lessonId}`,
    GET_LESSONS_BY_EPIC_ID: (epicId: number) => `/epics/${epicId}/lessons`,
};
