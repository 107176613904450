import React from 'react';
import AppLayout from '../../../components/layout/AppLayout';
import { Tabs } from 'antd';
import TargetList from './TargetList';

const BlocklyObjects: React.FC = () => {

    const items = [
        {
            label: `Tab 1`,
            key: "1",
            children: `Content of Tab 1`,
        },
        {
            label: `Target`,
            key: "2",
            children: <TargetList />,
        }
    ]
    return (
        <AppLayout title={`Blockly Objects`} >
            <>
                <Tabs
                    tabPosition="left"
                    items={items}
                />
            </>
        </AppLayout>
    );

};

export default BlocklyObjects;
