import React from 'react'
import BlocklyGame from '../../../blocks/core/component/BlocklyGame'
import { TCharacter } from '../../../blocks/core/type/character';
import { TScene } from '../../../blocks/core/type/scene';
import { BlocklyFields } from '../../../blocks/core/config/fields';
import { createBlocklyBlocks } from '../../../blocks/core/config/BlockyConfig';
import { getMrPen } from '../../../blocks/character/pen';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';

interface DrawFreeProps {
    checkResult: (isValid: boolean) => void; // Prop pour envoyer le résultat de l'exercice au parent
}

const DrawFree: React.FC<DrawFreeProps> = ({ checkResult }) => {
    const { t } = useTranslation();

    const character: TCharacter = getMrPen(0, 150);

    const scene: TScene = {
        width: 500,
        height: 500,
        gridRowWidth: 50,
        gridColumnWidth: 50,
    };

    const isValid = (isValid: boolean) => {
        checkResult(isValid)
        if (isValid) {
            message.success(t("message.bravo"));
        }
    }
    const initialBlocksJson = [

        {
            type: BlocklyFields.STARTBOX,
            x: 10, y: 10,
            deletable: false,
        }
    ]
    return (
        <>
            <BlocklyGame
                checkResult={isValid}
                character={character}
                scene={scene}
                drawResult={true}
                toolbox={{
                    "contents": createBlocklyBlocks([
                        { type: BlocklyFields.REPEAT },
                        { type: BlocklyFields.MOVE_RIGHT },
                        { type: BlocklyFields.MOVE_DOWN },
                        { type: BlocklyFields.MOVE_LEFT },
                        { type: BlocklyFields.MOVE_UP },
                        { type: BlocklyFields.MOVE_UPLEFT },
                        { type: BlocklyFields.MOVE_UPRIGHT },
                        { type: BlocklyFields.MOVE_DOWNRIGHT },
                        { type: BlocklyFields.MOVE_DOWNLEFT },
                    ])
                }}
                initialBlocksJsonOrXML={JSON.stringify(initialBlocksJson)}

            />
        </>
    )
}

export default React.memo(DrawFree);
