import React, { useEffect, useRef } from 'react';
import * as Blockly from 'blockly';
import { javascriptGenerator } from 'blockly/javascript';
import { buildBlocksXmlFromJSON } from '../config/BlockyConfig';
import { Button } from 'antd';
import { isJson } from '../../../utils/format/valueFormat';

interface BlocklyEditorProps {
  toolbox: any;
  initialBlocksJsonOrXML: any;
  onWorkspaceChange?: (workspace: Blockly.WorkspaceSvg) => void;
  xmlRef?: React.MutableRefObject<string>;
  cansave: boolean;
}

const BlocklyEditor: React.FC<BlocklyEditorProps> = ({
  toolbox,
  initialBlocksJsonOrXML,
  onWorkspaceChange,
  xmlRef,
  cansave,
}) => {
  const workspaceRef = useRef<HTMLDivElement | null>(null);
  const workspaceInstanceRef = useRef<Blockly.WorkspaceSvg | null>(null);

  const updatedToolboxRef = useRef<any>(toolbox); // Utilisation de useRef pour updatedToolbox
  const blockUsageCountRef = useRef<{ [blockType: string]: number }>({});

  // Fonction pour initialiser le workspace
  const initializeWorkspace = () => {
    if (workspaceRef.current) {
      // Nettoyer le contenu du conteneur pour éviter les duplications
      workspaceRef.current.innerHTML = '';

      try {
        const newWorkspace = Blockly.inject(workspaceRef.current, {
          toolbox: updatedToolboxRef.current, // Utilisation de updatedToolboxRef.current
          grid: {
            spacing: 20,
            length: 4,
            colour: '#ccc',
            snap: true,
          },
          zoom: {
            controls: true,
            wheel: true,
            startScale: 0.5,
            maxScale: 3,
            minScale: 0.3,
            scaleSpeed: 1.2,
          },
          trashcan: true,
        });

        workspaceInstanceRef.current = newWorkspace;
        javascriptGenerator.init(newWorkspace);

        newWorkspace.addChangeListener(handleBlockEvent);

        newWorkspace.addChangeListener(function (event) {
          if (event.type === Blockly.Events.FINISHED_LOADING) {
            newWorkspace.zoom(0, 0, 3);
            newWorkspace.scroll(0, 0);
          }
        });

        let xmlData = initialBlocksJsonOrXML
        if (isJson(initialBlocksJsonOrXML)) {
          xmlData = buildBlocksXmlFromJSON(JSON.parse(initialBlocksJsonOrXML));
        }
        const initialBlocksXml = xmlData;

        // Utiliser DOMParser pour convertir la chaîne XML en objet DOM
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(initialBlocksXml, 'text/xml');

        // Charger les blocs dans le workspace
        Blockly.Xml.domToWorkspace(xmlDoc.documentElement, newWorkspace);

        if (onWorkspaceChange) {
          onWorkspaceChange(newWorkspace);
        }
      } catch (error) {
        console.error('Erreur lors de l\'injection de Blockly:', error);
      }
    }
  };

  // Initialiser le workspace lors du premier rendu
  useEffect(() => {
    initializeWorkspace();

    return () => {
      if (workspaceInstanceRef.current) {
        workspaceInstanceRef.current.dispose();
        workspaceInstanceRef.current = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Réinitialiser le composant lorsque toolbox change
  useEffect(() => {
    // Réinitialiser 'updatedToolboxRef' avec la nouvelle 'toolbox'
    updatedToolboxRef.current = toolbox;

    // Réinitialiser le compteur d'utilisation des blocs
    blockUsageCountRef.current = {};

    // Réinitialiser le workspace
    if (workspaceInstanceRef.current) {
      workspaceInstanceRef.current.dispose();
      workspaceInstanceRef.current = null;
    }

    // Ré-initialiser le workspace avec la nouvelle toolbox
    initializeWorkspace();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toolbox]);

  // Trouver le nombre d'utilisations maximales à partir de la toolbox
  const getBlockMaxUsage = (blockType: string): number => {
    const blockInToolbox = toolbox.contents.find((block: any) => block.type === blockType);
    return blockInToolbox?.maxUsage ?? Infinity; // Par défaut, pas de limite
  };

  // Fonction pour mettre à jour la toolbox après modifications
  const updateToolbox = (modifications: { blockType: string; remove: boolean }[]) => {
    if (!workspaceInstanceRef.current) return;

    // Utiliser l'updatedToolboxRef actuel
    const newToolbox = JSON.parse(JSON.stringify(updatedToolboxRef.current));
    let toolboxChanged = false; // Flag pour vérifier si la toolbox a changé

    modifications.forEach(({ blockType, remove }) => {
      const blockIndex = newToolbox.contents.findIndex((item: any) => item.type === blockType);

      if (remove && blockIndex !== -1) {
        newToolbox.contents.splice(blockIndex, 1); // Supprimer le bloc
        toolboxChanged = true;

      } else if (!remove && blockIndex === -1) {
        const originalBlock = toolbox.contents.find((item: any) => item.type === blockType);
        if (originalBlock) {
          newToolbox.contents.push(originalBlock); // Réajouter le bloc
          toolboxChanged = true;

        }
      }
    });

    if (toolboxChanged) {
      // Mettre à jour updatedToolboxRef
      updatedToolboxRef.current = newToolbox;
      // Mettre à jour le workspace immédiatement
      workspaceInstanceRef.current.updateToolbox(newToolbox);
    }
  };

  // Fonction pour traiter les blocs créés ou supprimés
  const processBlockUsage = (
    blockJson: any,
    modifications: { blockType: string; remove: boolean }[],
    isCreation: boolean
  ) => {
    if (!blockJson) return;

    const blockType = blockJson.type;

    if (blockType) {
      const currentUsage = blockUsageCountRef.current[blockType] || 0;
      const usageNb = isCreation ? currentUsage + 1 : Math.max(currentUsage - 1, 0);
      blockUsageCountRef.current[blockType] = usageNb;
      const maxUsage = getBlockMaxUsage(blockType);


      if (isCreation) {
        // Ajouter l'action de suppression si la limite est atteinte
        if (usageNb >= maxUsage) {
          modifications.push({ blockType, remove: true });
        }
      } else {
        // Ajouter l'action de réajouter le bloc si besoin
        if (usageNb < maxUsage) {
          modifications.push({ blockType, remove: false });
        }
      }
    }

    // Traiter les blocs enfants (inputs, statements)
    if (blockJson.inputs) {
      Object.values(blockJson.inputs).forEach((input: any) => {
        if (input.block) {
          processBlockUsage(input.block, modifications, isCreation);
        }
      });
    }

    // Si ce bloc a un bloc "suivant", traiter le suivant
    if (blockJson.next && blockJson.next.block) {
      processBlockUsage(blockJson.next.block, modifications, isCreation);
    }
  };

  // Gestion des événements de création/suppression de bloc
  const handleBlockEvent = (event: any) => {
    if (!workspaceInstanceRef.current) return;

    const modifications: { blockType: string; remove: boolean }[] = [];

    if (event.type === Blockly.Events.BLOCK_CREATE && event.json) {
      // Traiter le bloc créé
      processBlockUsage(event.json, modifications, true);
    } else if (event.type === Blockly.Events.BLOCK_DELETE && event.oldJson) {
      // Traiter le bloc supprimé
      processBlockUsage(event.oldJson, modifications, false);
    }

    // Appliquer toutes les modifications en une seule fois
    if (modifications.length > 0) {
      updateToolbox(modifications);
    }
  };

  const getWorkspaceXml = () => {
    if (workspaceInstanceRef.current && xmlRef) {
      const dom = Blockly.Xml.workspaceToDom(workspaceInstanceRef.current);

      // Utiliser XMLSerializer pour convertir le DOM en texte XML
      const serializer = new XMLSerializer();
      const xml = serializer.serializeToString(dom);
      xmlRef.current = xml;
    }
  };

  return (
    <>
      {cansave && (
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 4 }}>
          <Button onClick={getWorkspaceXml} type="dashed" danger>
            Save Workspace
          </Button>
        </div>
      )}

      <div ref={workspaceRef} id="blocklyDiv" style={{ height: '500px', width: '100%' }} />
    </>
  );
};

export default BlocklyEditor;
