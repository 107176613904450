import { TCharacter } from "../core/type/character"
import idle from '../../assets/images/character/faizan.gif'
import move from '../../assets/images/character/faizan_move.gif'
import lose from '../../assets/images/character/faizan_lose.gif'
import win from '../../assets/images/character/faizan_win.gif'


export const getFaizan = (x: number, y: number): TCharacter => {
    return {
        x: x,
        y: y,
        state: 'idle',
        width: 50,
        height: 50,
        scale: 1,
        images: {
            idle: idle,
            moving: move,
            paused: idle,
            colliding: lose,
            win: win,
        },
        displacementUnit: 50,
        transition: {
            type: 'tween',
            ease: 'easeInOut', // Ou 'linear' pour une transition constante
            duration: 0.5,
        }
    }
}