import React, { useEffect, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { TCharacter } from '../type/character';
import Obstacle from './Obstacle';
import { TObstacle } from '../type/obstacle';
import { TScene } from '../type/scene';
import { TTarget } from '../type/target';
import Target from './Target';

interface IGameProps {
    character: TCharacter;
    obstacles?: TObstacle[];
    targets?: TTarget[];
    scene: TScene;
    showGrid?: boolean;
    movementHistory?: { x: number; y: number }[];  // Historique des mouvements  
    onAnimationComplete: () => void;
    editionMode?: boolean;

}

const IGame: React.FC<IGameProps> = ({
    character,
    obstacles = [],
    targets = [],
    scene,
    showGrid = true,
    movementHistory = [],
    onAnimationComplete,
    editionMode = false,
}) => {

    const controls = useAnimation();

    useEffect(() => {
        controls.start({
            scaleX: character.scale,
            x: character.x,
            y: character.y,
            transition: character.transition,
        }).then(() => {
            // Animation complete
            onAnimationComplete();
        });
        // eslint-disable-next-line
    }, [character.x, character.y, character.scale]);

    // Précharger les images lors du montage du composant
    useEffect(() => {
        const imagesToPreload = [
            character.images.idle,
            character.images.moving,
            character.images.paused,
            character.images.colliding
        ];

        imagesToPreload.forEach((src) => {
            const img = new Image();
            img.src = src;
        });
    }, [character.images]);



    const [styleScene, setStyleScene] = useState<React.CSSProperties>({})
    // Style pour la grille
    const getBackgroundStyle = (scene: TScene, showGrid: boolean): React.CSSProperties => {
        const backgroundImages: string[] = [];
        const backgroundSizes: string[] = [];
        const backgroundRepeats: string[] = [];

        // Ajouter l'image de fond si elle est définie
        if (scene.background?.img) {
            backgroundImages.push(`url(${scene.background.img})`);
            backgroundSizes.push(scene.background.size || 'cover');
            backgroundRepeats.push(scene.background.repeat ? 'repeat' : 'no-repeat');
        }

        // Ajouter la grille si showGrid est true
        if (showGrid) {
            // Lignes horizontales de la grille
            backgroundImages.push('linear-gradient(to right, lightgray 1px, transparent 1px)');
            backgroundSizes.push(`${scene.gridColumnWidth}px 100%`); // Largeur de la colonne, hauteur 100%
            backgroundRepeats.push('repeat-x'); // Répéter horizontalement

            // Lignes verticales de la grille
            backgroundImages.push('linear-gradient(to bottom, lightgray 1px, transparent 1px)');
            backgroundSizes.push(`100% ${scene.gridRowWidth}px`); // Largeur 100%, hauteur de la ligne
            backgroundRepeats.push('repeat-y'); // Répéter verticalement
        }

        // Vérifier s'il y a des images d'arrière-plan à appliquer
        if (backgroundImages.length === 0) {
            return {
                backgroundImage: 'none',
            };
        } else {
            return {
                backgroundImage: backgroundImages.join(', '),
                backgroundSize: backgroundSizes.join(', '),
                backgroundRepeat: backgroundRepeats.join(', '),
            };
        }
    };
    useEffect(() => {
        setStyleScene(getBackgroundStyle(scene, showGrid))
    }, [scene, showGrid])


    const getCharacterImage = () => {
        switch (character.state) {
            case 'idle':
                return character.images.idle;
            case 'moving':
                return character.images.moving;
            case 'paused':
                return character.images.paused;
            case 'colliding':
                return character.images.colliding;
            case 'wining':
                return character.images.win;
        }
    };


    return (
        <div
            id="scene"
            style={{
                position: 'relative',
                width: `${scene.width}px`,
                height: `${scene.height}px`,
                border: '1px solid black',
                ...styleScene,
            }}
        >
            {/* Affichage des obstacles */}
            {obstacles.map((obstacle, index) => (
                <Obstacle key={index} obstacle={obstacle} editionMode={editionMode} />
            ))}

            {/* Affichage des target */}
            {targets.map((target, index) => (
                <Target key={index} target={target} editionMode={editionMode} />
            ))}

            {/* SVG pour tracer les lignes de mouvement */}
            <svg
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    pointerEvents: 'none', // Permet de ne pas interférer avec les interactions
                }}
            >
                {/* Tracer les lignes entre chaque mouvement successif */}
                {movementHistory.length > 1 &&
                    movementHistory.map((movement, index) => {
                        if (index === 0) return null; // Pas de ligne pour le premier point

                        const previousMovement = movementHistory[index - 1];

                        return (
                            <line
                                key={index}
                                x1={previousMovement.x + character.width / 2}  // Départ de la ligne
                                y1={previousMovement.y + character.height / 2} // Ajuster pour centrer
                                x2={movement.x + character.width / 2}  // Fin de la ligne
                                y2={movement.y + character.height / 2} // Ajuster pour centrer
                                stroke="black"  // Couleur de la ligne
                                strokeWidth="5"  // Épaisseur de la ligne
                                strokeLinecap="round"  // Rendre les extrémités arrondies
                                strokeLinejoin="round"  // Rendre les jonctions arrondies
                            />
                        );
                    })}
            </svg>

            {/* Animation du personnage */}
            <motion.div
                id={"character"}
                style={{
                    width: `${character.width}px`,
                    height: `${character.height}px`,
                    backgroundImage: `url(${getCharacterImage()})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat', // Correction de la répétition d'image
                    position: 'absolute',
                    backgroundPosition: 'center',
                    border: editionMode ? '2px dashed blue' : 'none', // Bordure noire dashed si editionMode est vrai 
                }}
                animate={controls}
            />
        </div>
    );
};

export default IGame;
