import React, { useEffect, useState } from 'react';
import AppLayout from '../../../components/layout/AppLayout';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Button, DatePicker, Input, message, Space, Table } from 'antd';
import { SaveOutlined, EditOutlined, StopOutlined } from '@ant-design/icons';
import type { ColumnsType } from 'antd/es/table';  // Import ColumnsType
import { useUserApi } from '../../../api/user/userApp';
import { TPassword, TUserApp } from '../../../models/user/User';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';

// Configurer Day.js avec les plugins
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

const { RangePicker } = DatePicker;

const PasswordList: React.FC = () => {
    const { t } = useTranslation();
    const location = useLocation();

    const { getPasswordsByUserAppId, createPassword, updatePassword } = useUserApi();
    const [passwords, setPasswords] = useState<TPassword[]>([]);
    const [loading, setIsLoading] = useState<boolean>(false);
    const [addingId, setAddingId] = useState<number>(0);

    // On suppose que l'objet userapp est passé via location.state depuis un autre composant
    const userapp: TUserApp = location.state?.userapp;

    useEffect(() => {
        if (!userapp) {
            message.error('No userapp selected');
            return;
        }

        setIsLoading(true);
        getPasswordsByUserAppId(userapp.id)
            .then((resp) => {
                setPasswords(resp.data);
            })
            .catch((err) => {
                message.error(err.response.data ?? err.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
        // eslint-disable-next-line
    }, [userapp]);

    const [editingKey, setEditingKey] = useState<number | null>(null);
    const [editingPassword, setEditingPassword] = useState<TPassword | null>(null);

    // Fonction pour entrer en mode édition
    const edit = (password: TPassword) => {
        setEditingKey(password.id);
        setEditingPassword({ ...password, password: "" });
    };

    const cancel = () => {
        setEditingKey(null);
        setEditingPassword(null);
    }

    // Fonction pour ajouter un nouveau mot de passe
    const handleAddPassword = () => {
        const idRow = addingId - 1;
        const newPassword: TPassword = {
            id: idRow,
            userapp_id: userapp.id,
            userapp: userapp,
            password: '',
            start_time: new Date().toISOString(),
            end_time: undefined  // Utiliser undefined pour indiquer l'absence de fin
        };

        setAddingId(idRow);
        setEditingKey(idRow);
        setEditingPassword(newPassword);
        setPasswords((prev) => [
            newPassword,
            ...(prev || []),
        ]);
    };

    // Sauvegarder les modifications
    const save = (id: number) => {

        if (editingPassword) {
            setIsLoading(true);

            (editingPassword.id > 0
                ? updatePassword(editingPassword.id, editingPassword)
                : createPassword(editingPassword))
                .then((resp) => {
                    const passwordResult: TPassword = resp.data;
                    const updatedPasswords = passwords.map((password) =>
                        password.id === id ? passwordResult : password
                    );
                    setPasswords(updatedPasswords);
                    setEditingKey(null);
                    setEditingPassword(null);
                    message.success(t('message.savedSuccessfully'));
                })
                .catch((err) => {
                    message.error(err.response.data ?? err.message);
                    console.error(err);
                })
                .finally(() => {
                    setIsLoading(false);
                });


        }
    };

    const columns: ColumnsType<TPassword> = [
        {
            title: 'Password',
            dataIndex: 'password',
            key: 'password',
            render: (text: string, record: TPassword) =>
                editingKey === record.id ? (
                    <Input
                        value={editingPassword?.password}
                        onChange={(e) => {
                            if (editingPassword) {
                                setEditingPassword({ ...editingPassword, password: e.target.value });
                            }
                        }}
                    />
                ) : (
                    '••••••••'  // Masquer le mot de passe
                ),
        },
        {
            title: 'Time Range',
            key: 'time_range',
            render: (_: any, record: TPassword) =>
                editingKey === record.id ? (
                    <RangePicker
                        showTime
                        format="YYYY-MM-DD HH:mm:ss"
                        value={
                            editingPassword?.start_time
                                ? [
                                    dayjs(editingPassword.start_time),
                                    editingPassword.end_time ? dayjs(editingPassword.end_time) : null,
                                ]
                                : null
                        }
                        onChange={(dates: [Dayjs | null, Dayjs | null] | null, dateStrings: [string, string]) => {
                            if (dates) {
                                const [start, end] = dates;
                                setEditingPassword({
                                    ...editingPassword!,
                                    start_time: start ? start.toISOString() : '',
                                    end_time: end ? end.toISOString() : undefined,
                                });
                            } else {
                                setEditingPassword({
                                    ...editingPassword!,
                                    start_time: '',
                                    end_time: undefined,
                                });
                            }
                        }}
                    />
                ) : (
                    `${dayjs(record.start_time).format('DD/MM/YYYY HH:mm:ss')} - ${record.end_time ? dayjs(record.end_time).format('DD/MM/YYYY HH:mm:ss') : 'N/A'}`
                ),
        },
        {
            title: 'Action',
            key: 'actions',
            fixed: 'right' as 'right',  // Fixer la colonne à droite
            width: 180,  // Largeur fixe
            render: (_: any, record: TPassword) => (
                <Space size="middle">
                    {editingKey === record.id ? (
                        <>
                            <Button icon={<SaveOutlined />} onClick={() => save(record.id)} />
                            <Button icon={<StopOutlined />} onClick={cancel} />
                        </>
                    ) : (
                        <>
                            <Button icon={<EditOutlined />} onClick={() => edit(record)} />
                        </>
                    )}
                </Space>
            ),
        },
    ];

    return (
        <AppLayout title={`User : ${userapp?.login}`} loading={loading}>
            <Space style={{ marginBottom: 16 }}>
                <Button type="primary" onClick={handleAddPassword}>
                    {t('button.add')}
                </Button>
            </Space>
            <Table<TPassword>
                columns={columns}
                dataSource={passwords}
                rowKey="id"
                loading={loading}
                pagination={{ pageSize: 20 }}  // Pagination avec 20 mots de passe par page
                scroll={{ x: 500 }}  // Activer le défilement horizontal si nécessaire
            />
        </AppLayout>
    );
};

export default PasswordList;
