import React, { useState } from 'react';
import { Select, Input, Space, Button, Table } from 'antd';
import { TTarget } from '../../../../models/objects/objects';
import { TLesson, TLessonTarget } from '../../../../models/courses/lesson';
import { DeleteOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import { getImagePath } from '../../../../utils/format/valueFormat';

const { Option } = Select;

interface TargetProps {
    targets: TTarget[];
    editingLesson: TLesson | undefined;
    setEditingLesson: React.Dispatch<React.SetStateAction<TLesson | undefined>>;
}

const Target: React.FC<TargetProps> = ({ targets, editingLesson, setEditingLesson }) => {

    const [addingId, setAddingId] = useState<number>(0);

    const [editingKey, setEditingKey] = useState<number | null>(null);
    const [editingTarget, setEditingTarget] = useState<TLessonTarget | null>(null);

    // Fonction pour passer en mode édition
    const edit = (target: TLessonTarget) => {
        setEditingKey(target.id);
        setEditingTarget({ ...target });
    };

    // Fonction pour gérer l'ajout d'un nouveau cours
    const handleAddCourse = () => {
        const idRow = addingId - 1
        const newTarget: TLessonTarget = {
            id: idRow,
            targetId: 0,
            lessonId: 0,
            x: 0,
            y: 0,
            width: 0,
            height: 0,
            targetOrder: 0,
            quantity: 0,
            rotate: 0,
            target: {} as TTarget

        };

        setAddingId(idRow);
        setEditingKey(idRow);
        setEditingTarget(newTarget);

        setEditingLesson((prevLesson) => {
            if (!prevLesson) return prevLesson;

            return {
                ...prevLesson,
                targets: [
                    ...(prevLesson.targets || []), // Spread the existing targets array
                    newTarget                      // Add the new target
                ]
            };
        });

    };


    // Sauvegarde les modifications
    const save = (id: number) => {
        if (!editingTarget) {
            return;
        }

        setEditingLesson((prevLesson) => {
            if (!prevLesson) return prevLesson;
            const newTargets = prevLesson.targets?.map((target) => {
                if (target.id === id) {
                    return { ...editingTarget, id };
                }
                return target;
            });

            return {
                ...prevLesson,
                targets: newTargets,
            };
        });

        setEditingKey(null);
        setEditingTarget(null);
    };


    const handleDelete = (id: number) => {
        setEditingLesson((prevLesson) => {
            if (!prevLesson) return prevLesson;

            return {
                ...prevLesson,
                targets: prevLesson.targets?.filter((target) => target.id !== id),
            };
        });
    };

    const handleInputChange = (field: string, value: string) => {
        setEditingTarget((prev) => {
            if (!prev) return prev;
            return { ...prev, [field]: Number(value) };
        });
    };

    const handleTargetChange = (value: number) => {
        const selectedTarget = targets.find((t) => t.id === value);
        setEditingTarget((prev) => {
            if (!prev) return prev;
            return { ...prev, targetId: value, target: selectedTarget || ({} as TTarget) };
        });
    };

    const columns = [
        {
            title: 'Target',
            dataIndex: 'target',
            key: 'target',
            width: 200,
            render: (text: string, record: TLessonTarget) =>
                editingKey === record.id ? (
                    <Select
                        placeholder={'Target'}
                        style={{ width: '100%' }}
                        onChange={(value) => handleTargetChange(value)}
                        value={editingTarget?.targetId}
                    >
                        {targets.map((tar) => (
                            <Option key={tar.id} value={tar.id}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img
                                        src={getImagePath(tar?.images?.[0]?.img ?? "")}
                                        alt={tar.name}
                                        style={{ width: '30px', height: '30px', marginRight: '10px' }}
                                    />
                                    <span>{tar.name}</span>
                                </div>
                            </Option>
                        ))}
                    </Select>
                ) : (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img
                            src={getImagePath(record?.target?.images?.[0]?.img ?? "")}
                            alt={record.target.name}
                            style={{ width: '30px', height: '30px', marginRight: '10px' }}
                        />
                        <span>{record.target.name}</span>
                    </div>
                ),
        },
        {
            title: 'X',
            dataIndex: 'x',
            key: 'x',
            render: (text: number, record: TLessonTarget) =>
                editingKey === record.id ? (
                    <Input
                        value={editingTarget?.x}
                        onChange={(e) => handleInputChange('x', e.target.value)}
                    />
                ) : (
                    text
                ),
        },
        {
            title: 'Y',
            dataIndex: 'y',
            key: 'y',
            render: (text: number, record: TLessonTarget) =>
                editingKey === record.id ? (
                    <Input
                        value={editingTarget?.y}
                        onChange={(e) => handleInputChange('y', e.target.value)}
                    />
                ) : (
                    text
                ),
        },
        {
            title: 'Width',
            dataIndex: 'width',
            key: 'width',
            render: (text: number, record: TLessonTarget) =>
                editingKey === record.id ? (
                    <Input
                        value={editingTarget?.width}
                        onChange={(e) => handleInputChange('width', e.target.value)}
                    />
                ) : (
                    text
                ),
        },
        {
            title: 'Height',
            dataIndex: 'height',
            key: 'height',
            render: (text: number, record: TLessonTarget) =>
                editingKey === record.id ? (
                    <Input
                        value={editingTarget?.height}
                        onChange={(e) => handleInputChange('height', e.target.value)}
                    />
                ) : (
                    text
                ),
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
            render: (text: number, record: TLessonTarget) =>
                editingKey === record.id ? (
                    <Input
                        value={editingTarget?.quantity}
                        onChange={(e) => handleInputChange('quantity', e.target.value)}
                    />
                ) : (
                    text
                ),
        },
        {
            title: 'Rotate',
            dataIndex: 'rotate',
            key: 'rotate',
            render: (text: number, record: TLessonTarget) =>
                editingKey === record.id ? (
                    <Input
                        value={editingTarget?.rotate}
                        onChange={(e) => handleInputChange('rotate', e.target.value)}
                    />
                ) : (
                    text
                ),
        },
        {
            title: 'Order',
            dataIndex: 'targetOrder',
            key: 'targetOrder',
            render: (text: number, record: TLessonTarget) =>
                editingKey === record.id ? (
                    <Input
                        value={editingTarget?.targetOrder}
                        onChange={(e) => handleInputChange('targetOrder', e.target.value)}
                    />
                ) : (
                    text
                ),
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text: string, record: TLessonTarget) => (
                <Space size="middle">
                    {editingKey === record.id ? (
                        <Button
                            icon={<SaveOutlined />}
                            onClick={() => save(record.id)}
                        />
                    ) : (
                        <>
                            <Button
                                icon={<EditOutlined />}
                                onClick={() => edit(record)}
                            />
                            <Button
                                icon={<DeleteOutlined />}
                                onClick={() => handleDelete(record.id)}
                            />
                        </>
                    )}
                </Space>
            ),
        },
    ];

    return (
        <Space direction="vertical" style={{ width: '100%' }}>
            <Button type="primary" onClick={handleAddCourse} disabled={editingKey !== null}>
                Add Target
            </Button>
            <Table
                dataSource={editingLesson?.targets}
                columns={columns}
                rowKey="id"
                pagination={false}
            />
        </Space>
    );
};

export default Target;