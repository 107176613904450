// useApiFunctions.js
import { TCharacter, TObstacle, TScene, TTarget } from '../../models/objects/objects';
import { useApiClient } from '../apiClient';
import { ENDPOINTS_CHARACTER, ENDPOINTS_OBSTACLE, ENDPOINTS_SCENE, ENDPOINTS_TARGET } from './endpoints';

export const useObjectsApi = () => {
    const apiClient = useApiClient();

    // character
    const getCharacters = () => apiClient.get<TCharacter[]>(ENDPOINTS_CHARACTER.GET_ALL);
    const createCharacter = (data: TCharacter) => apiClient.post(ENDPOINTS_CHARACTER.POST, data);
    const updateCharacter = (id: number, data: TCharacter) => apiClient.put(ENDPOINTS_CHARACTER.PUT(id), data);
    const deleteCharacter = (id: number) => apiClient.delete(ENDPOINTS_CHARACTER.DELETE(id));

    // obstacle
    const getObstacles = () => apiClient.get<TObstacle[]>(ENDPOINTS_OBSTACLE.GET_ALL);
    const createObstacle = (data: TObstacle) => apiClient.post(ENDPOINTS_OBSTACLE.POST, data);
    const updateObstacle = (id: number, data: TObstacle) => apiClient.put(ENDPOINTS_OBSTACLE.PUT(id), data);
    const deleteObstacle = (id: number) => apiClient.delete(ENDPOINTS_OBSTACLE.DELETE(id));

    // scene
    const getScenes = () => apiClient.get<TScene[]>(ENDPOINTS_SCENE.GET_ALL);
    const createScene = (data: TScene) => apiClient.post(ENDPOINTS_SCENE.POST, data);
    const updateScene = (id: number, data: TScene) => apiClient.put(ENDPOINTS_SCENE.PUT(id), data);
    const deleteScene = (id: number) => apiClient.delete(ENDPOINTS_SCENE.DELETE(id));

    // scene
    const getTargets = () => apiClient.get<TTarget[]>(ENDPOINTS_TARGET.GET_ALL);
    const createTarget = (data: TTarget) => apiClient.post(ENDPOINTS_TARGET.POST, data);
    const updateTarget = (id: number, data: TTarget) => apiClient.put(ENDPOINTS_TARGET.PUT(id), data);
    const deleteTarget = (id: number) => apiClient.delete(ENDPOINTS_TARGET.DELETE(id));
    const uploadTargetImage = (courseId: number, stateId: number, formData: FormData) => {
        return apiClient.post<TTarget>(ENDPOINTS_TARGET.UPLOAD_IMAGE(courseId, stateId), formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
    };


    return {
        getCharacters,
        createCharacter,
        updateCharacter,
        deleteCharacter,

        getObstacles,
        createObstacle,
        updateObstacle,
        deleteObstacle,

        getScenes,
        createScene,
        updateScene,
        deleteScene,

        getTargets,
        createTarget,
        updateTarget,
        deleteTarget,
        uploadTargetImage,

    };
};
