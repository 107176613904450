



export const ENDPOINTS_USERAPP = {
    GET_ALL: '/userapps',
    GET_BY_ID: (id: number) => `/userapps/${id}`,
    POST: '/userapps',
    PUT: (id: number) => `/userapps/${id}`,
    DELETE: (id: number) => `/userapps/${id}`,
};

export const ENDPOINTS_PASSWORDS = {
    GET_BY_IDUSERAPP: (id: number) => `/passwords/userapp/${id}`,
    POST: '/passwords',
    PUT: (id: number) => `/passwords/${id}`,
    DELETE: (id: number) => `/passwords/${id}`,
};
