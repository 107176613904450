// components/exerciseComponents.ts
import React from 'react';
import DnDLevelOne from './earlyChildhood/DigitalCitizenship/DnD/DnDLevelOne';
import DnDLevelTwo from './earlyChildhood/DigitalCitizenship/DnD/DnDLevelTwo';
import DnDLevelThree from './earlyChildhood/DigitalCitizenship/DnD/DnDLevelThree';
import DnDLevelFour from './earlyChildhood/DigitalCitizenship/DnD/DnDLevelFour';
import SqcLevelOne from './earlyChildhood/sequencing/intro/SqcLevelOne';
import SqcLevelTwo from './earlyChildhood/sequencing/intro/SqcLevelTwo';
import SqcLevelThree from './earlyChildhood/sequencing/intro/SqcLevelThree';
import SqcDevLevelEight from './earlyChildhood/sequencing/dev/SqcDevLevelEight';
import SqcDevLevelSix from './earlyChildhood/sequencing/dev/SqcDevLevelSix';
import SqcDevLevelFive from './earlyChildhood/sequencing/dev/SqcDevLevelFive';
import SqcDevLevelFour from './earlyChildhood/sequencing/dev/SqcDevLevelFour';
import SqcDevLevelThree from './earlyChildhood/sequencing/dev/SqcDevLevelThree';
import SqcDevLevelTwo from './earlyChildhood/sequencing/dev/SqcDevLevelTwo';
import SqcDevLevelOne from './earlyChildhood/sequencing/dev/SqcDevLevelOne';
import SqcLevelSix from './earlyChildhood/sequencing/intro/SqcLevelSix';
import SqcLevelFive from './earlyChildhood/sequencing/intro/SqcLevelFive';
import SqcLevelFour from './earlyChildhood/sequencing/intro/SqcLevelFour';
import SqcDevLevelSeven from './earlyChildhood/sequencing/dev/SqcDevLevelSeven';
import SqcDevBBLevelOne from './earlyChildhood/sequencing/BB8/SqcDevBBLevelOne';
import SqcDevBBLevelTwo from './earlyChildhood/sequencing/BB8/SqcDevBBLevelTwo';
import SqcDevBBLevelThree from './earlyChildhood/sequencing/BB8/SqcDevBBLevelThree';
import SqcDevBBLevelFour from './earlyChildhood/sequencing/BB8/SqcDevBBLevelFour';
import SqcDevBBLevelFive from './earlyChildhood/sequencing/BB8/SqcDevBBLevelFive';
import SqcDevBBLevelSix from './earlyChildhood/sequencing/BB8/SqcDevBBLevelSix';
import SqcDevBBLevelSeven from './earlyChildhood/sequencing/BB8/SqcDevBBLevelSeven';
import SqcDevBBLevelEight from './earlyChildhood/sequencing/BB8/SqcDevBBLevelEight';
import SqcDevBBLevelNine from './earlyChildhood/sequencing/BB8/SqcDevBBLevelNine';
import SqcDevBBLevelTen from './earlyChildhood/sequencing/BB8/SqcDevBBLevelTen';
import LoopsScartLevelOne from './earlyChildhood/Loops/Scart/LoopsScartLevelOne';
import LoopsScartLevelTwo from './earlyChildhood/Loops/Scart/LoopsScartLevelTwo';
import LoopsScartLevelThree from './earlyChildhood/Loops/Scart/LoopsScartLevelThree';
import LoopsScartLevelFour from './earlyChildhood/Loops/Scart/LoopsScartLevelFour';
import LoopsScartLevelFive from './earlyChildhood/Loops/Scart/LoopsScartLevelFive';
import LoopsScartLevelSix from './earlyChildhood/Loops/Scart/LoopsScartLevelSix';
import LoopsScartLevelSeven from './earlyChildhood/Loops/Scart/LoopsScartLevelSeven';
import LoopsScartLevelEight from './earlyChildhood/Loops/Scart/LoopsScartLevelEight';
import LoopsScartLevelNine from './earlyChildhood/Loops/Scart/LoopsScartLevelNine';
import LoopsScartLevelTen from './earlyChildhood/Loops/Scart/LoopsScartLevelTen';
import LoopsLaurelLevelOne from './earlyChildhood/Loops/Laurel/LoopsLaurelLevelOne';
import LoopsLaurelLevelTwo from './earlyChildhood/Loops/Laurel/LoopsLaurelLevelTwo';
import LoopsLaurelLevelThree from './earlyChildhood/Loops/Laurel/LoopsLaurelLevelThree';
import LoopsLaurelLevelFour from './earlyChildhood/Loops/Laurel/LoopsLaurelLevelFour';
import LoopsLaurelLevelFive from './earlyChildhood/Loops/Laurel/LoopsLaurelLevelFive';
import LoopsLaurelLevelSix from './earlyChildhood/Loops/Laurel/LoopsLaurelLevelSix';
import LoopsLaurelLevelSeven from './earlyChildhood/Loops/Laurel/LoopsLaurelLevelSeven';
import LoopsLaurelLevelEight from './earlyChildhood/Loops/Laurel/LoopsLaurelLevelEight';
import LoopsLaurelLevelNine from './earlyChildhood/Loops/Laurel/LoopsLaurelLevelNine';
import LoopsLaurelLevelTen from './earlyChildhood/Loops/Laurel/LoopsLaurelLevelTen';
import LoopsLaurelLevelEleven from './earlyChildhood/Loops/Laurel/LoopsLaurelLevelEleven';
import LoopsOceanLevelOne from './earlyChildhood/Loops/Ocean/LoopsOceanLevelOne';
import DnDLevelFive from './earlyChildhood/DigitalCitizenship/DnD/DnDLevelFive';
import SqcDevLevelNine from './earlyChildhood/sequencing/dev/SqcDevLevelNine';

import LoopsOceanLevelTwo from './earlyChildhood/Loops/Ocean/LoopsOceanLevelTwo';
import LoopsOceanLevelThree from './earlyChildhood/Loops/Ocean/LoopsOceanLevelThree';
import LoopsOceanLevelFour from './earlyChildhood/Loops/Ocean/LoopsOceanLevelFour';
import LoopsOceanLevelFive from './earlyChildhood/Loops/Ocean/LoopsOceanLevelFive';
import LoopsOceanLevelSix from './earlyChildhood/Loops/Ocean/LoopsOceanLevelSix';
import LoopsOceanLevelSeven from './earlyChildhood/Loops/Ocean/LoopsOceanLevelSeven';
import LoopsOceanLevelEight from './earlyChildhood/Loops/Ocean/LoopsOceanLevelEight';
import LoopsOceanLevelNine from './earlyChildhood/Loops/Ocean/LoopsOceanLevelNine';
import LoopsOceanLevelTen from './earlyChildhood/Loops/Ocean/LoopsOceanLevelTen';
import DnDLevelSix from './earlyChildhood/DigitalCitizenship/DnD/DnDLevelSix';
import DnDLevelSeven from './earlyChildhood/DigitalCitizenship/DnD/DnDLevelSeven';
import DnDLevelEight from './earlyChildhood/DigitalCitizenship/DnD/DnDLevelEight';
import DnDLevelNine from './earlyChildhood/DigitalCitizenship/DnD/DnDLevelNine';
import DnDLevelTen from './earlyChildhood/DigitalCitizenship/DnD/DnDLevelTen';
import DnDLevelEleven from './earlyChildhood/DigitalCitizenship/DnD/DnDLevelEleven';
import DnDLevelTwelve from './earlyChildhood/DigitalCitizenship/DnD/DnDLevelTwelve';
import DrawBoat from './earlyChildhood/challenge/DrawBoat';
import DrawFree from './earlyChildhood/challenge/DrawFree';
import FCL1 from './earlyChildhood/Loops/finalChallenge/FCL1';
import FCL2 from './earlyChildhood/Loops/finalChallenge/FCL2';
import FCL3 from './earlyChildhood/Loops/finalChallenge/FCL3';
import FCL4 from './earlyChildhood/Loops/finalChallenge/FCL4';
import FCL5 from './earlyChildhood/Loops/finalChallenge/FCL5';
import FCL6 from './earlyChildhood/Loops/finalChallenge/FCL6';
import FCL7 from './earlyChildhood/Loops/finalChallenge/FCL7';
import FCL8 from './earlyChildhood/Loops/finalChallenge/FCL8';
import FCL9 from './earlyChildhood/Loops/finalChallenge/FCL9';
import FCL10 from './earlyChildhood/Loops/finalChallenge/FCL10';


// Types pour les composants d'exercice
interface ExerciseComponents {
    [key: string]: React.FC<any>;  // Permet à chaque composant de recevoir des props spécifiques
}

// Déclare un type qui associe des noms de composants à des composants React
export const exerciseComponents: ExerciseComponents = {
    // Drag and Drop
    DnDLevelOne: DnDLevelOne,
    DnDLevelTwo: DnDLevelTwo,
    DnDLevelThree: DnDLevelThree,
    DnDLevelFour: DnDLevelFour,
    DnDLevelFive: DnDLevelFive,
    DnDLevelSix: DnDLevelSix,
    DnDLevelSeven: DnDLevelSeven,
    DnDLevelEight: DnDLevelEight,
    DnDLevelNine: DnDLevelNine,
    DnDLevelTen: DnDLevelTen,
    DnDLevelEleven: DnDLevelEleven,
    DnDLevelTwelve: DnDLevelTwelve,

    // Sequencing
    // -- intro
    SqcLevelOne: SqcLevelOne,
    SqcLevelTwo: SqcLevelTwo,
    SqcLevelThree: SqcLevelThree,
    SqcLevelFour: SqcLevelFour,
    SqcLevelFive: SqcLevelFive,
    SqcLevelSix: SqcLevelSix,
    // -- dev
    SqcDevLevelOne: SqcDevLevelOne,
    SqcDevLevelTwo: SqcDevLevelTwo,
    SqcDevLevelThree: SqcDevLevelThree,
    SqcDevLevelFour: SqcDevLevelFour,
    SqcDevLevelFive: SqcDevLevelFive,
    SqcDevLevelSix: SqcDevLevelSix,
    SqcDevLevelSeven: SqcDevLevelSeven,
    SqcDevLevelEight: SqcDevLevelEight,
    SqcDevLevelNine: SqcDevLevelNine,
    // -- BB8
    SqcDevBBLevelOne: SqcDevBBLevelOne,
    SqcDevBBLevelTwo: SqcDevBBLevelTwo,
    SqcDevBBLevelThree: SqcDevBBLevelThree,
    SqcDevBBLevelFour: SqcDevBBLevelFour,
    SqcDevBBLevelFive: SqcDevBBLevelFive,
    SqcDevBBLevelSix: SqcDevBBLevelSix,
    SqcDevBBLevelSeven: SqcDevBBLevelSeven,
    SqcDevBBLevelEight: SqcDevBBLevelEight,
    SqcDevBBLevelNine: SqcDevBBLevelNine,
    SqcDevBBLevelTen: SqcDevBBLevelTen,
    // Loops
    // -- Scart
    LoopsScartLevelOne: LoopsScartLevelOne,
    LoopsScartLevelTwo: LoopsScartLevelTwo,
    LoopsScartLevelThree: LoopsScartLevelThree,
    LoopsScartLevelFour: LoopsScartLevelFour,
    LoopsScartLevelFive: LoopsScartLevelFive,
    LoopsScartLevelSix: LoopsScartLevelSix,
    LoopsScartLevelSeven: LoopsScartLevelSeven,
    LoopsScartLevelEight: LoopsScartLevelEight,
    LoopsScartLevelNine: LoopsScartLevelNine,
    LoopsScartLevelTen: LoopsScartLevelTen,
    // -- Laurel
    LoopsLaurelLevelOne: LoopsLaurelLevelOne,
    LoopsLaurelLevelTwo: LoopsLaurelLevelTwo,
    LoopsLaurelLevelThree: LoopsLaurelLevelThree,
    LoopsLaurelLevelFour: LoopsLaurelLevelFour,
    LoopsLaurelLevelFive: LoopsLaurelLevelFive,
    LoopsLaurelLevelSix: LoopsLaurelLevelSix,
    LoopsLaurelLevelSeven: LoopsLaurelLevelSeven,
    LoopsLaurelLevelEight: LoopsLaurelLevelEight,
    LoopsLaurelLevelNine: LoopsLaurelLevelNine,
    LoopsLaurelLevelTen: LoopsLaurelLevelTen,
    LoopsLaurelLevelEleven: LoopsLaurelLevelEleven,
    // -- Ocean
    FCL1: FCL1,
    FCL2: FCL2,
    FCL3: FCL3,
    FCL4: FCL4,
    FCL5: FCL5,
    FCL6: FCL6,
    FCL7: FCL7,
    FCL8: FCL8,
    FCL9: FCL9,
    FCL10: FCL10,
    LoopsOceanLevelOne: LoopsOceanLevelOne,
    LoopsOceanLevelTwo: LoopsOceanLevelTwo,
    LoopsOceanLevelThree: LoopsOceanLevelThree,
    LoopsOceanLevelFour: LoopsOceanLevelFour,
    LoopsOceanLevelFive: LoopsOceanLevelFive,
    LoopsOceanLevelSix: LoopsOceanLevelSix,
    LoopsOceanLevelSeven: LoopsOceanLevelSeven,
    LoopsOceanLevelEight: LoopsOceanLevelEight,
    LoopsOceanLevelNine: LoopsOceanLevelNine,
    LoopsOceanLevelTen: LoopsOceanLevelTen,

    // -- challenge
    DrawBoat: DrawBoat,
    DrawFree: DrawFree,
};
