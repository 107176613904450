import React from 'react';
import { TObstacle } from '../type/obstacle';

interface ObstacleProps {
    obstacle: TObstacle;
    editionMode?: boolean;
}

const Obstacle: React.FC<ObstacleProps> = ({ obstacle, editionMode = false }) => {
    return (
        <div
            style={{
                position: 'absolute',
                top: obstacle.y,
                left: obstacle.x,
                width: `${obstacle.width}px`,
                height: `${obstacle.height}px`,
                backgroundColor: obstacle.image ? 'transparent' : 'gray',  // Si l'image n'est pas fournie, on affiche un carré gris
                backgroundImage: obstacle.image ? `url(${obstacle.image})` : 'none',
                backgroundSize: 'contain',   // Ajuste l'image pour couvrir tout le carré
                transform: `rotate(${obstacle.rotate ?? 0}deg)`,
                backgroundRepeat: 'no-repeat',
                border: editionMode ? '2px dashed red' : 'none', // Bordure noire dashed si editionMode est vrai 
            }}
        />
    );
}

export default Obstacle;
