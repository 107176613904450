// src/blocks/move/moveRight.ts

import * as Blockly from 'blockly';
import { javascriptGenerator } from 'blockly/javascript';
import { BlocklyFields } from '../../fields';
import i18next from 'i18next';

export function initJumpDownBlock() {

    Blockly.Blocks[BlocklyFields.JUMP_DOWN] = {
        init: function () {
            this.jsonInit({
                "type": BlocklyFields.JUMP_DOWN,
                "message0": '%1 ' + i18next.t('blockly.jump.down.message', 'Jump down'),
                "args0": [
                    {
                        "type": "field_image",
                        "src": "/images/blocks/down.png", // Lien vers votre icône (chemin relatif ou absolu)
                        "width": 16,   // Largeur de l'image
                        "height": 16,  // Hauteur de l'image

                    }
                ],
                "previousStatement": null,
                "nextStatement": null,
                "colour": '#E69A14',
                "tooltip": i18next.t('blockly.jump.down.tooltip', ''),
                "helpUrl": ""
            });

        },
    };
    javascriptGenerator.forBlock[BlocklyFields.JUMP_DOWN] = function (block, generator) {
        return 'await jumpCharacter(90);\n';
    }

}
