import React from 'react'
import BlocklyGame from '../../../blocks/core/component/BlocklyGame'
import { TCharacter } from '../../../blocks/core/type/character';
import { TScene } from '../../../blocks/core/type/scene';
import { BlocklyFields } from '../../../blocks/core/config/fields';
import { createBlocklyBlocks } from '../../../blocks/core/config/BlockyConfig';
import { TTarget, createTarget } from '../../../blocks/core/type/target';
import cross from '../../../assets/images/target/cross.png'
import { getMrPen } from '../../../blocks/character/pen';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

interface DrawBoatProps {
    checkResult: (isValid: boolean) => void; // Prop pour envoyer le résultat de l'exercice au parent
}


const DrawBoat: React.FC<DrawBoatProps> = ({ checkResult }) => {
    const { t } = useTranslation();

    const character: TCharacter = getMrPen(250, 150);

    const scene: TScene = {
        width: 500,
        height: 500,
        gridRowWidth: 50,
        gridColumnWidth: 50,
    };
    let order = 1;
    const targets: TTarget[] = [
        //drapeau
        createTarget(300, 100, 50, 50, cross, { order: order++ }),
        createTarget(250, 50, 50, 50, cross, { order: order++ }),
        createTarget(250, 100, 50, 50, cross, { order: order++ }),
        createTarget(250, 200, 50, 50, cross, { order: order++ }),
        // createTarget(250, 200, 50, 50, cross, { order: order++ }),
        createTarget(400, 200, 50, 50, cross, { order: order++ }),
        createTarget(350, 250, 50, 50, cross, { order: order++ }),
        createTarget(250, 250, 50, 50, cross, { order: order++ }),
        createTarget(150, 250, 50, 50, cross, { order: order++ }),
        createTarget(100, 200, 50, 50, cross, { order: order++ }),
        createTarget(200, 200, 50, 50, cross, { order: order++ }),

    ]
    const isValid = (isValid: boolean) => {
        checkResult(isValid)
        if (isValid) {
            message.success(t("message.bravo"));
        } else {
            message.warning(t("message.bug"));
        }
    }
    const initialBlocksJson = [

        {
            type: BlocklyFields.STARTBOX,
            x: 10, y: 10,
            deletable: false,
            next: {
                block: {
                    type: BlocklyFields.MOVE_UPRIGHT,
                    x: 20, y: 10,
                }
            }
        }
    ]
    return (
        <>
            <BlocklyGame
                checkResult={isValid}
                character={character}
                scene={scene}
                drawResult={true}
                respectOrder={true}
                autoReset={false}
                targets={targets}
                toolbox={{
                    "contents": createBlocklyBlocks([
                        { type: BlocklyFields.REPEAT },
                        { type: BlocklyFields.MOVE_RIGHT },
                        { type: BlocklyFields.MOVE_DOWN },
                        { type: BlocklyFields.MOVE_LEFT },
                        { type: BlocklyFields.MOVE_UP },
                        { type: BlocklyFields.MOVE_UPLEFT },
                        { type: BlocklyFields.MOVE_UPRIGHT },
                        { type: BlocklyFields.MOVE_DOWNRIGHT },
                        { type: BlocklyFields.MOVE_DOWNLEFT },
                    ])
                }}
                initialBlocksJsonOrXML={JSON.stringify(initialBlocksJson)}

            />
        </>
    )
}

export default React.memo(DrawBoat);
