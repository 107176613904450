import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import routes from './routes/routes';
import LoadingBox from './components/loading/LoadingBox';
import { ReferentialProvider } from './context/ReferentialContext';
import { AuthProvider } from './context/AuthContext';

const App: React.FC = () => {
  return (
    <Suspense fallback={<LoadingBox />}>
      <I18nextProvider i18n={i18n}>
        <DndProvider backend={HTML5Backend}>
          <Router>

            <AuthProvider>
              <ReferentialProvider>
                <Routes>
                  {routes.map((route, index) => (
                    <Route key={index} path={route.path} element={route.element} />
                  ))}
                </Routes>
              </ReferentialProvider>
            </AuthProvider>
          </Router>
        </DndProvider>
      </I18nextProvider>
    </Suspense>
  );
};

export default App;
