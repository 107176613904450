// blocklyUtils.ts
import * as Blockly from 'blockly';
import { BlocklyFields } from './fields';

// blocklyUtils.ts

export const executeAsyncCode = async (code: string, context: any) => {
    const asyncCode = `
      (async () => {
        ${code}
      })();
    `;

    try {
        // eslint-disable-next-line
        const func = new Function(...Object.keys(context), asyncCode);

        // eslint-disable-next-line
        await func(...Object.values(context));
    } catch (error) {
        console.error('Erreur lors de l\'exécution du code :', error);
    }
};

export const CheckNextCondition = (allBlocks: Blockly.BlockSvg[], processedBlockIds: string[]): boolean => {
    let nextIsNull = 0;

    for (const block of allBlocks) {
        // Ignorer les blocs déjà traités
        if (processedBlockIds.includes(block.id)) continue;

        processedBlockIds.push(block.id);

        if (block.type !== BlocklyFields.REPEAT) {
            if (block.getNextBlock() === null) {
                nextIsNull++;
                if (nextIsNull > 1) return false;
            }
        } else {
            let firstChildBlock = block.getInputTargetBlock('DO');
            let childrenBlocks: Blockly.BlockSvg[] = [];

            while (firstChildBlock) {
                childrenBlocks.push(firstChildBlock as Blockly.BlockSvg);
                firstChildBlock = firstChildBlock.getNextBlock();
            }
            if (!CheckNextCondition(childrenBlocks, processedBlockIds)) return false;
        }
    }

    return true;
};
