import React from 'react';
import { useTranslation } from 'react-i18next';
import { Layout, Menu, Divider, Typography } from 'antd';
import { BlockOutlined, BookOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';  // Utilisation du contexte Auth
import packageJson from '../../../package.json';

const { Sider } = Layout;
const { Text } = Typography;

interface SidebarProps {
    isCollapsed: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({ isCollapsed }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const { isRoleExist } = useAuth();  // Récupère la fonction depuis le contexte Auth

    const handleMenuClick = (e: any) => {
        navigate(e.key);
    };

    // Configuration de la section de menu
    const menuSections = [
        {
            title: t('sidebar.general'), // Section général
            items: [
                {
                    key: '1',
                    icon: <BookOutlined />,
                    label: t('sidebar.program'),
                    path: '/',   // Main path
                    subpages: ['/program', '/course-details/:courseId', '/course-progression/:lessonId'],
                },
            ],
        },
        {
            title: t('sidebar.settings'), // Section Configuration
            items: isRoleExist('COURSE_CONFIGURATOR') ? [  // Afficher seulement si le rôle existe
                {
                    key: '2',
                    icon: <SettingOutlined />,
                    label: t('sidebar.course'),
                    path: '/setup/course',
                    subpages: ['/setup/chapter', '/setup/epic', '/setup/lesson', '/setup/lesson-details'],
                },
                {
                    key: '2',
                    icon: <UserOutlined />,
                    label: t('sidebar.users'),
                    path: '/setup/users',
                    subpages: ['/setup/passwords'],
                },
                {
                    key: '2',
                    icon: <BlockOutlined />,
                    label: t('sidebar.blocklyobjects'),
                    path: '/setup/blockly-objects',
                    subpages: [],
                },
            ] : [],  // Si aucun rôle, pas d'élément
        },
    ];

    // Fonction pour matcher les chemins dynamiques
    const isPathMatching = (menuPath: string, currentPath: string) => {
        if (menuPath.includes(':')) {
            const pattern = menuPath.split('/:')[0];
            return currentPath.startsWith(pattern);
        }
        return currentPath === menuPath;
    };

    // Récupérer la clé du menu sélectionné
    const getSelectedMenuKey = () => {
        const currentPath = location.pathname;
        for (const section of menuSections) {
            for (const item of section.items) {
                if (
                    isPathMatching(item.path, currentPath) ||
                    (item.subpages && item.subpages.some(subpage => isPathMatching(subpage, currentPath)))
                ) {
                    return item.path;
                }
            }
        }
        return "1";
    };

    return (
        <Sider
            width={isCollapsed ? 80 : 250}
            collapsed={isCollapsed}
            style={{
                background: '#fff',
                boxShadow: '2px 0 2px rgba(0, 0, 0, 0.1)',
                zIndex: 2,
                position: 'fixed',
                left: 0,
                top: 0,
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                transition: 'width 0.3s ease',
            }}
        >
            {/* Logo et menu en haut */}
            <div>
                <div style={{ padding: '10px 0', textAlign: 'center' }}>
                    <img
                        src={!isCollapsed ? "/images/logo_400.png" : "/images/logoMin_100.png"}
                        alt="Logo"
                        style={{
                            height: '48px',
                            margin: '0 auto',
                            transition: 'opacity 0.3s ease',
                        }}
                    />
                </div>

                <Divider style={{ margin: '8px 0' }} />

                {/* Boucle sur les sections du menu */}
                {menuSections.map((section, index) => (
                    section.items.length > 0 && (  // Afficher seulement si la section a des éléments visibles
                        <div key={index}>
                            {/* Titre de la section */}
                            {!isCollapsed && (
                                <Text
                                    style={{
                                        fontSize: '12px',
                                        color: '#888',
                                        textTransform: 'uppercase',
                                        letterSpacing: '0.1em',
                                        margin: '16px 16px 8px',
                                        display: 'block',
                                    }}
                                >
                                    {section.title}
                                </Text>
                            )}

                            {/* Items du menu */}
                            <Menu
                                mode="vertical"
                                selectedKeys={[getSelectedMenuKey()]}
                                items={section.items.map(item => ({
                                    key: item.path,
                                    icon: item.icon,
                                    label: isCollapsed ? null : item.label,
                                }))}
                                style={{ borderRight: 0 }}
                                onClick={handleMenuClick}
                            />

                            {/* Divider entre sections */}
                            {index < menuSections.length - 1 && <Divider style={{ margin: '8px 0' }} />}
                        </div>
                    )
                ))}
            </div>

            {/* Affichage de la version en bas */}
            <div
                style={{
                    position: 'absolute',
                    bottom: '16px',
                    width: '100%',
                    textAlign: 'center',
                    color: '#888',
                }}
            >
                <span style={{ fontSize: isCollapsed ? '10px' : '12px' }}>
                    Version {packageJson.version}
                </span>
            </div>
        </Sider>
    );
};

export default Sidebar;
