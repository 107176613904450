import React, { useEffect, useState } from 'react';
import { useObjectsApi } from '../../../api/objects/objects';
import { TTarget } from '../../../models/objects/objects';
import { Button, Input, message, Space, Upload } from 'antd';
import { useTranslation } from 'react-i18next';
import Table, { ColumnsType } from 'antd/es/table';
import { EditOutlined, EyeOutlined, SaveOutlined, UploadOutlined } from '@ant-design/icons';
import { useReferential } from '../../../context/ReferentialContext';
import { TCodification } from '../../../models/referential/referential';
import { CODIFICATION_TYPE } from '../../../constant/codification';

const TargetList: React.FC = () => {
    const { t } = useTranslation();
    const { getTargets, createTarget, updateTarget, uploadTargetImage } = useObjectsApi();
    const [targets, setTargets] = useState<TTarget[]>([]);
    const [loading, setIsLoading] = useState<boolean>(false);
    const [addingId, setAddingId] = useState<number>(0);
    const { getCodificationsByCodeType } = useReferential();

    const states: TCodification[] = getCodificationsByCodeType(CODIFICATION_TYPE.TARGET_STATE)

    useEffect(() => {
        setIsLoading(true);
        getTargets()
            .then((resp) => {
                setTargets(resp.data);
            })
            .catch((err) => {
                message.error(err.response.data ?? err.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
        // eslint-disable-next-line
    }, []);

    const [editingKey, setEditingKey] = useState<number | null>(null);
    const [editingObject, setEditingObject] = useState<TTarget | null>(null);

    const edit = (target: TTarget) => {
        setEditingKey(target.id);
        setEditingObject({ ...target });
    };

    const handleAddCourse = () => {
        const idRow = addingId - 1;
        const newCourse: TTarget = {
            id: idRow,
            name: ""
        };

        setAddingId(idRow);
        setEditingKey(idRow);
        setEditingObject(newCourse);
        setTargets((prev) => [newCourse, ...prev]);
    };

    const save = (id: number) => {
        if (editingObject) {
            (editingObject.id > 0 ? updateTarget(editingObject.id, editingObject) : createTarget(editingObject))
                .then((resp) => {
                    const targetResult: TTarget = resp.data;
                    const updatedTargets = targets.map((target) =>
                        target.id === id ? targetResult : target
                    );
                    setTargets(updatedTargets);
                    message.success(t('message.savedSuccessfully'));
                })
                .catch((err) => {
                    message.error(err.response.data ?? err.message);
                    console.error(err);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }

        setEditingKey(null);
    };


    const uploadImage = (target: TTarget, state: TCodification) => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';

        input.onchange = async (event: any) => {
            const file = event.target.files[0];
            if (file) {
                const formData = new FormData();
                formData.append('image', file);

                uploadTargetImage(target.id, state.id, formData)
                    .then((resp) => {
                        const targetResult: TTarget = resp.data;
                        const updatedTargets = targets.map((crs) =>
                            crs.id === target.id ? targetResult : crs
                        );
                        setTargets(updatedTargets);
                        message.success(t('message.savedSuccessfully'));
                    })
                    .catch((err) => {
                        message.error(err.response.data ?? err.message);
                        console.error(err);
                    });
            }
        };

        input.click();
    };

    const columns: ColumnsType<TTarget> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text: string, record: TTarget) =>
                editingKey === record.id ? (
                    <Input
                        value={editingObject?.name}
                        onChange={(e) => {
                            if (editingObject) {
                                setEditingObject({ ...editingObject, name: e.target.value });
                            }
                        }}
                    />
                ) : (
                    text
                ),
        },
        // Colonnes dynamiques pour chaque état
        ...states.map((state) => ({
            title: state.label,
            dataIndex: `state_${state.id}`,
            key: `state_${state.id}`,
            render: (_: any, record: TTarget) => {
                const image = record.images?.find((img) => img.state_id === state.id);
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img
                            src={image?.img || 'https://via.placeholder.com/50'}
                            alt={`${state.label} image`}
                            style={{ width: 50, height: 50, marginRight: 8 }}
                        />
                        {editingKey !== record.id && (
                            <Upload
                                showUploadList={false}
                                beforeUpload={(file) => {
                                    uploadImage(record, state);
                                    return false; // Empêche le comportement par défaut du téléchargement
                                }}
                            >
                                <Button icon={<UploadOutlined />} size="small" />
                            </Upload>
                        )}
                    </div>
                );
            },
        })),
        {
            title: 'Actions',
            key: 'actions',
            fixed: 'right' as 'right',
            width: 180,
            render: (_: any, record: TTarget) => (
                <Space size="middle">
                    {editingKey === record.id ? (
                        <Button icon={<SaveOutlined />} onClick={() => save(record.id)} />
                    ) : (
                        <>
                            <Button icon={<EditOutlined />} onClick={() => edit(record)} />
                        </>
                    )}
                </Space>
            ),
        },
    ];

    return (
        <>
            <Space style={{ marginBottom: 16 }}>
                <Button type="primary" onClick={handleAddCourse}>
                    {t('button.add')}
                </Button>
            </Space>
            <Table<TTarget>
                columns={columns}
                dataSource={targets}
                rowKey="id"
                loading={loading}
                pagination={{ pageSize: 20 }}
                scroll={{ x: 1000 }}
            />
        </>
    );
};

export default TargetList;
