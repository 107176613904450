// src/blocks/move/moveRight.ts

import * as Blockly from 'blockly';
import { javascriptGenerator } from 'blockly/javascript';
import { BlocklyFields } from '../../fields';
import i18next from 'i18next';

export function initMoveUpLeftBlock() {

    Blockly.Blocks[BlocklyFields.MOVE_UPLEFT] = {
        init: function () {
            this.jsonInit({
                "type": BlocklyFields.MOVE_UPLEFT,
                "message0": '%1 ' + i18next.t('blockly.diagonal.upleft.message', 'Move up left'),
                "args0": [
                    {
                        "type": "field_image",
                        "src": "/images/blocks/upleft.png", // Lien vers votre icône (chemin relatif ou absolu)
                        "width": 16,   // Largeur de l'image
                        "height": 16,  // Hauteur de l'image

                    }
                ],
                "previousStatement": null,
                "nextStatement": null,
                "colour": '#FF6680', // Vous pouvez choisir une autre couleur si vous le souhaitez
                "tooltip": i18next.t('blockly.diagonal.upleft.tooltip', ''),
                "helpUrl": ""
            });
        },
    };

    javascriptGenerator.forBlock[BlocklyFields.MOVE_UPLEFT] = function (block, generator) {
        return 'await walkCharacter(225);\n';
    }
}
