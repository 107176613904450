import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, Typography, Row, Col, Button, Steps, message, Divider, Collapse } from 'antd';  // Importer Collapse  
import AppLayout from '../../components/layout/AppLayout';
import { useTranslation } from 'react-i18next';
import { TChapter, TCourse } from '../../models/courses/course';
import { useCourseApi } from '../../api/courses/courses';
import { getImagePath } from '../../utils/format/valueFormat';

const { Title, Paragraph } = Typography;
const { Step } = Steps;

const CourseDetails: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { courseId } = useParams();  // Récupérer l'ID du cours depuis l'URL
    const { getCoursesDetailsById } = useCourseApi();
    const [course, setCourse] = useState<TCourse | null>(null)
    const [loading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        setIsLoading(true);
        getCoursesDetailsById(Number(courseId))
            .then((response) => {
                setCourse(response.data);
            })
            .catch((err) => {
                message.error(err.response.data ?? err.message)
            })
            .finally(() => {
                setIsLoading(false);
            })
        // eslint-disable-next-line
    }, []);


    // Utiliser les ID au lieu des index pour la navigation
    const goToProgress = (lessonId: number) => {
        navigate(`/course-progression/${lessonId}`);
    };

    // Préparer les items pour Collapse
    const collapseItems = (course?.chapters || []).map((chapter: TChapter) => ({
        key: chapter.id.toString(),
        label: (
            <div style={{ color: '#931161', fontWeight: 'bold' }}>
                {chapter.name}
            </div>
        ),
        children: (chapter.epics || []).map((epic, indexEpic) => (
            <div key={epic.id} style={{ marginBottom: '32px', overflow: 'hidden' }}>
                <Title level={5} style={{ marginBottom: '24px', color: "success" }}>
                    {epic.name}
                </Title>

                <Steps
                    direction="horizontal"
                    size="small"
                    onChange={(current) => goToProgress(epic.lessons[current].id)}
                    style={{
                        marginTop: '12px',
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'left',
                        rowGap: '20px',
                    }}
                    current={-1}
                >
                    {(epic.lessons || []).map((lesson) => (
                        <Step key={lesson.id} title={`${lesson.name}`} />
                    ))}
                </Steps>
                {indexEpic !== chapter.epics.length - 1 && (<Divider />)}
            </div>
        ))
    }));

    return (
        <AppLayout title='Cours' loading={loading}>
            {!course ? (
                <div>{t('text.courseUnavailable')}</div>
            ) : (
                <>
                    <Card style={{ padding: '24px' }}>
                        <Row gutter={16} align="middle">
                            <Col xs={24} sm={8} md={6}>
                                <img alt={course.title} src={getImagePath(course.image)} style={{ width: '100%', borderRadius: '8px' }} />
                            </Col>
                            <Col xs={24} sm={16} md={18}>
                                <Title level={2}>{course.title}</Title>
                                <Paragraph>{course.description}</Paragraph>
                                <Paragraph>
                                    {t('text.courseRecommandedAge')} : {course.min_age} - {course.max_age} {t('text.years')}
                                </Paragraph>
                                <Button
                                    type="primary"
                                    size="large"
                                    style={{ marginTop: '16px' }}
                                    onClick={() => goToProgress(course.chapters[0].epics[0].lessons[0].id)}
                                >
                                    {t('button.enter')}
                                </Button>
                            </Col>
                        </Row>
                    </Card>

                    {/* Utilisation de items dans Collapse */}
                    <Collapse accordion defaultActiveKey={['-1']} style={{ marginTop: '24px' }} size="large" items={collapseItems} />
                </>
            )}
        </AppLayout>
    );
};

export default CourseDetails;
