import { apiPath } from "../../config/config";

export const stringToBool = (value: string | undefined | null): boolean => {

    return ((value && value === "true") || false)
}

export const isJson = (str: any): boolean => {
    try {
        JSON.parse(str); // Tente de parser la chaîne
        return true;
    } catch (e) {
        return false; // Si une erreur survient, ce n'est pas un JSON valide
    }
}

export const getImagePath = (img: string, reloadCache?: boolean): string => {
    let url: string;

    if (reloadCache && reloadCache === true) {
        const timestamp = new Date().getTime();
        url = new URL(img, apiPath).toString() + `?v=${timestamp}`
    } else {
        url = new URL(img, apiPath).toString()
    }
    return url
}