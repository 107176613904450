import React, { useRef } from 'react'
import BlocklyGame from '../../../../blocks/core/component/BlocklyGame';
import { TLesson } from '../../../../models/courses/lesson';
import { TLessonCharacterTOTCharacter } from '../../../../utils/blockly/character';
import { TLessonObstaclesTOObstacles } from '../../../../utils/blockly/obstacle';
import { TLessonTargetsTOTTargets } from '../../../../utils/blockly/target';
import { TLessonSceneTOScene } from '../../../../utils/blockly/scene';
import { stringToBool } from '../../../../utils/format/valueFormat';
import { useReferential } from '../../../../context/ReferentialContext';
import { TLessonBlocklyContentTOBlocklyBlocks } from '../../../../utils/blockly/blocklyfields';
import { CODIFICATION_LESSON_METADATA, CODIFICATION_TYPE } from '../../../../constant/codification';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';

interface LessonBlocklyProps {
    lesson: TLesson | null;
    checkResult: (isValid: boolean) => void;

}
const LessonBlockly: React.FC<LessonBlocklyProps> = ({ lesson, checkResult }) => {
    const { getCodificationIdByCodes, getCodificationCodeById } = useReferential();
    const { t } = useTranslation();
    // Créez une ref mutable pour xmlRef
    const xmlRef = useRef<string>(lesson?.metadata?.find(
        (e) => e.key_id === getCodificationIdByCodes(CODIFICATION_TYPE.LESSON_METADATA, CODIFICATION_LESSON_METADATA.BLOCKLY_XML_TOOLBOX)
    )?.value || '');

    const isValid = (isValid: boolean) => {
        checkResult(isValid)
        if (isValid) {
            message.success(t("message.bravo"));
        } else {
            message.warning(t("message.bug"));
        }
    }

    return (
        <>
            {lesson ? (
                <BlocklyGame
                    toolbox={{
                        "contents": TLessonBlocklyContentTOBlocklyBlocks(lesson?.blocklycontent, getCodificationCodeById)
                    }}
                    initialBlocksJsonOrXML={lesson?.metadata?.find(e => e.key_id === getCodificationIdByCodes(CODIFICATION_TYPE.LESSON_METADATA, CODIFICATION_LESSON_METADATA.BLOCKLY_XML_TOOLBOX))?.value}
                    character={TLessonCharacterTOTCharacter(lesson?.character, getCodificationIdByCodes)}
                    obstacles={TLessonObstaclesTOObstacles(lesson?.obstacles)}
                    targets={TLessonTargetsTOTTargets(lesson?.targets, getCodificationIdByCodes)}
                    scene={TLessonSceneTOScene(lesson?.scene)}
                    xmlRef={xmlRef}
                    showGrid={stringToBool(lesson?.metadata?.find(e => e.key_id === getCodificationIdByCodes(CODIFICATION_TYPE.LESSON_METADATA, CODIFICATION_LESSON_METADATA.SHOW_GRID))?.value) || false}
                    drawResult={stringToBool(lesson?.metadata?.find(e => e.key_id === getCodificationIdByCodes(CODIFICATION_TYPE.LESSON_METADATA, CODIFICATION_LESSON_METADATA.DRAW_RESULT))?.value) || false}
                    autoReset={stringToBool(lesson?.metadata?.find(e => e.key_id === getCodificationIdByCodes(CODIFICATION_TYPE.LESSON_METADATA, CODIFICATION_LESSON_METADATA.AUTO_RESET))?.value) || false}
                    autoReach={stringToBool(lesson?.metadata?.find(e => e.key_id === getCodificationIdByCodes(CODIFICATION_TYPE.LESSON_METADATA, CODIFICATION_LESSON_METADATA.AUTO_REACH))?.value) || false}
                    respectOrder={stringToBool(lesson?.metadata?.find(e => e.key_id === getCodificationIdByCodes(CODIFICATION_TYPE.LESSON_METADATA, CODIFICATION_LESSON_METADATA.RESPECT_ORDER))?.value) || false}
                    mustReachAllTargets={stringToBool(lesson?.metadata?.find(e => e.key_id === getCodificationIdByCodes(CODIFICATION_TYPE.LESSON_METADATA, CODIFICATION_LESSON_METADATA.REACH_ALL_TARGET))?.value) || false}
                    checkResult={isValid}
                />
            ) : (
                <p>{t('text.exercideUnavailable')}</p>
            )}


        </>
    )
}

export default React.memo(LessonBlockly);
