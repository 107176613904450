// src/blockly/BlocklyExecutor.ts

import { javascriptGenerator } from 'blockly/javascript';
import * as Blockly from 'blockly';

export const executeAsyncCode = async (
  workspace: Blockly.WorkspaceSvg,
  context: any,
): Promise<void> => {
  try {
    let code = javascriptGenerator.workspaceToCode(workspace);
    code += `
        await isDone();
      `;
    const asyncCode = `
        (async () => {
          ${code}
        })();
      `;

    // eslint-disable-next-line
    const func = new Function(...Object.keys(context), `${asyncCode}`);
    const result = func(...Object.values(context));

    if (result instanceof Promise) {
      await result;
    }


  } catch (error) {
    console.error('Error executing Blockly code:', error);
  }
};
