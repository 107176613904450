import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CourseLayout from '../../components/layout/CourseLayout';
import { exerciseComponents } from '../../courses/exerciseComponents';
import { Card, Col, message, Row } from 'antd';
import LoadingBox from '../../components/loading/LoadingBox';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { TCourse } from '../../models/courses/course';
import { TLesson } from '../../models/courses/lesson';
import { useCourseApi } from '../../api/courses/courses';
import { findEpicInCourse } from '../../models/courses/courseFunc';
import LessonBlockly from './lessonType/gameBlockly/LessonBlockly';

const CourseProgress: React.FC = () => {
    const navigate = useNavigate();
    const { lessonId } = useParams();  // Récupérer les paramètres d'URL

    const [canProceed, setCanProceed] = useState(false);  // Pour activer/désactiver le bouton "Suivant"  
    const { getLessonDetailsById } = useCourseApi();
    const [lesson, setLesson] = useState<TLesson | null>(null);
    const [isLoading, setIsLoading] = useState(true);  // État de chargement
    const [currentLessonId, setCurrentLessonId] = useState<number>(Number(lessonId))

    const [course, setCourse] = useState<TCourse | null>(null);
    const { getCoursesDetailsByIdLesson } = useCourseApi();


    // Charger les détails du cours en fonction du lessonId
    useEffect(() => {
        getCoursesDetailsByIdLesson(Number(lessonId))
            .then((response) => {
                setCourse(response.data);
            })
            .catch((err) => {
                console.error("Error fetching course:", err);
            });
        // eslint-disable-next-line
    }, []);


    // Nouveaux états pour gérer les leçons et l'indice actuel
    const [lessons, setLessons] = useState<TLesson[]>([]);
    const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);

    useEffect(() => {
        if (currentLessonId > 0) {
            setIsLoading(true);
            getLessonDetailsById(currentLessonId)
                .then((response) => {
                    setLesson(response.data);
                })
                .catch((err) => {
                    message.error(err.response.data ?? err.message);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
        // eslint-disable-next-line
    }, [currentLessonId]);

    useEffect(() => {
        if (lesson && course) {
            const epicLessons = findEpicInCourse(course, lesson.epic_id)?.lessons || [];
            setLessons(epicLessons);

            const index = epicLessons.findIndex(l => l.id === lesson.id);
            setCurrentStepIndex(index);
            setCanProceed(false); // Réinitialise canProceed lors du changement de leçon
        }
    }, [lesson, course]);

    const next = () => {
        if (currentStepIndex + 1 < lessons.length) {
            goToStep(currentStepIndex + 1);
        }
    };

    const previous = () => {
        if (currentStepIndex > 0) {
            goToStep(currentStepIndex - 1);
        }
    };

    const goToStep = (stepIndex: number) => {
        const targetLesson = lessons[stepIndex];
        if (targetLesson) {
            setCurrentLessonId(targetLesson.id)
            window.history.pushState({}, '', `/course-progression/${targetLesson.id}`);
        }
    };

    const finishCourse = () => {
        if (course) {
            navigate(`/course-details/${course.id}`);
        } else {
            navigate(`/program`);
        }
    };

    const checkResult = useCallback((isValid: boolean) => {
        setCanProceed(isValid);
    }, []);

    const SelectedExerciseComponent = lesson?.exercise_component ? exerciseComponents[lesson.exercise_component] : null;

    return (
        <CourseLayout
            title={`Progression: ${lesson?.epic?.name || '...'}`}
            lessons={lessons}
            currentStep={currentStepIndex}
            previous={currentStepIndex > 0 ? previous : undefined}
            next={canProceed && currentStepIndex + 1 < lessons.length ? next : undefined}
            finishCourse={canProceed && currentStepIndex + 1 === lessons.length ? finishCourse : undefined}
            goToStep={goToStep}
            isLoading={isLoading}
        >
            {isLoading ? (
                <LoadingBox />
            ) : (
                <>
                    <Row style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <Col>
                            <ArrowLeftOutlined
                                onClick={finishCourse}
                                style={{ cursor: 'pointer', fontSize: '18px' }}
                            />
                        </Col>
                        <Col>
                            <h3 style={{ margin: 0 }}>{`${lesson?.name || 'Chargement...'}`}</h3>
                        </Col>
                    </Row>

                    <Row justify="center">
                        <Col span={24}>
                            <Card
                                title={
                                    <div style={{ whiteSpace: 'pre-line' }}>
                                        {lesson?.statement || 'Chargement...'}
                                    </div>
                                }
                                bordered={true}
                                style={{
                                    minHeight: 600,
                                    textAlign: 'center',
                                    backgroundColor: '#f5f5f5',
                                    position: 'relative',
                                }}
                            >
                                {SelectedExerciseComponent ? (
                                    <SelectedExerciseComponent checkResult={checkResult} />
                                ) : (
                                    <LessonBlockly key={lesson?.id || 0} lesson={lesson} checkResult={checkResult} />
                                )}
                            </Card>
                        </Col>
                    </Row>
                </>
            )}
        </CourseLayout>
    );
};

export default CourseProgress;
