
export const BlocklyFields = {
    STARTBOX: "starting_box",

    //statement
    REPEAT: "repeat_custom",

    // action
    GET_TARGET: "get_target",

    //move
    MOVE_UP: "move_up",
    MOVE_DOWN: "move_down",
    MOVE_LEFT: "move_left",
    MOVE_RIGHT: "move_right",

    JUMP_UP: "jump_up",
    JUMP_DOWN: "jump_down",
    JUMP_LEFT: "jump_left",
    JUMP_RIGHT: "jump_right",

    MOVE_UPRIGHT: "move_upRight",
    MOVE_UPLEFT: "move_upLeft",
    MOVE_DOWNRIGHT: "move_downRight",
    MOVE_DOWNLEFT: "move_downLeft",
}