import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';  // Importer le AuthContext

interface ProtectedRouteProps {
    element: React.ReactElement;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element }) => {
    const { getToken } = useAuth();  // Utiliser le AuthContext pour récupérer le token

    // Si un token valide est présent, retourner l'élément correspondant à la route
    if (getToken()) {
        return element;
    }

    // Si l'utilisateur n'est pas connecté, rediriger vers la page de connexion
    return <Navigate to="/" />;
};

export default ProtectedRoute;
