// src/blocks/move/moveRight.ts

import * as Blockly from 'blockly';
import { javascriptGenerator } from 'blockly/javascript';
import { BlocklyFields } from '../../fields';
import i18next from 'i18next';

export function initMoveDownRightBlock() {

    Blockly.Blocks[BlocklyFields.MOVE_DOWNRIGHT] = {
        init: function () {
            this.jsonInit({
                type: BlocklyFields.MOVE_DOWNRIGHT,
                "message0": '%1 ' + i18next.t('blockly.diagonal.downright.message', 'Move down right'),
                args0: [
                    {
                        type: 'field_image',
                        src: '/images/blocks/downright.png',
                        width: 16,
                        height: 16,
                    },
                ],
                previousStatement: null,
                nextStatement: null,
                colour: '#4C97FF',
                tooltip: i18next.t('blockly.diagonal.downright.tooltip', ''),
                helpUrl: '',
            });
        },
    };

    javascriptGenerator.forBlock[BlocklyFields.MOVE_DOWNRIGHT] = function (block) {
        return 'await walkCharacter(45);\n';
    };
}
