// src/components/BlocklyGame.tsx

import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, InputNumber, Row, Space } from 'antd';
import BlocklyEditor from '../workspace/BlocklyEditor';
import { useBlocklyWorkspace } from '../config/useBlocklyWorkspace';
import { useTranslation } from 'react-i18next';
import IGame from './IGame';
import { useGameLogic } from './GameLogic';
import { executeAsyncCode } from './BlocklyExecutor';
import { TCharacter } from '../type/character';
import { TObstacle } from '../type/obstacle';
import { TTarget } from '../type/target';
import { TScene } from '../type/scene';
import * as Blockly from 'blockly';

interface BlocklyGameProps {
  checkResult: (isValid: boolean) => void;
  toolbox: any;
  initialBlocksJsonOrXML: any;
  character: TCharacter;
  obstacles?: TObstacle[];
  targets?: TTarget[];
  scene?: TScene;
  showGrid?: boolean;
  drawResult?: boolean;
  autoReach?: boolean;
  autoReset?: boolean;
  respectOrder?: boolean;
  mustReachAllTargets?: boolean;
  canChangeXY?: boolean;
  xmlRef?: React.MutableRefObject<string>;
  cansave?: boolean;
  editionMode?: boolean;
}

const BlocklyGame: React.FC<BlocklyGameProps> = ({
  checkResult,
  toolbox,
  initialBlocksJsonOrXML,
  character,
  obstacles = [],
  targets = [],
  scene = { width: 500, height: 500, gridRowWidth: 50, gridColumnWidth: 50 },
  showGrid = false,
  drawResult = false,
  autoReset = true,
  autoReach = true,
  respectOrder = false,
  mustReachAllTargets = true,
  canChangeXY = false,
  xmlRef,
  cansave = false,
  editionMode = false
}) => {
  const { t } = useTranslation();
  const executionCancelled = useRef<boolean>(false);

  const [characterState, setCharacterState] = useState<TCharacter>(character);
  const [movementHistory, setMovementHistory] = useState<{ x: number; y: number }[]>([
    { x: character.x, y: character.y },
  ]);

  const initialTargets = React.useMemo(() => targets.sort((a, b) => (a.order || 0) - (b.order || 0)), [targets]);
  const [remainingTargets, setRemainingTargets] = useState<TTarget[]>(initialTargets);
  const remainingTargetsRef = useRef<TTarget[]>(remainingTargets);

  useEffect(() => {
    remainingTargetsRef.current = remainingTargets;
  }, [remainingTargets]);

  const [workspace, setWorkspace] = useState<Blockly.WorkspaceSvg | null>(null);
  const { isRunButtonEnabled } = useBlocklyWorkspace(workspace);
  // Créer une référence pour characterState
  const characterStateRef = useRef<TCharacter>(character);

  // Mettre à jour la référence à chaque changement de characterState
  useEffect(() => {
    characterStateRef.current = characterState;
  }, [characterState]);

  // probleme de mise à jours lors d'une modification du parent
  useEffect(() => {
    setCharacterState(character)
  }, [character]);

  // Initialize or update remainingTargets when targets prop changes
  useEffect(() => {
    // Sort the targets
    const sortedTargets = [...targets].sort((a, b) => (a.order || 0) - (b.order || 0));
    setRemainingTargets(sortedTargets);
  }, [targets]);

  // eslint-disable-next-line
  const animationPromiseResolverRef = useRef<(() => void) | null>(null);



  const pauseCharacter = () => {
    setCharacterState((prev) => ({
      ...prev,
      state: 'paused',
    }));
  };


  const [checkCompletion, setCheckCompletion] = useState(false);
  // Importer la logique du jeu
  const { moveCharacter, resetGame, reachTarget, reachTargetByXY } = useGameLogic({
    character,
    characterState,
    characterStateRef,
    obstacles,
    initialTargets,
    remainingTargets,
    setRemainingTargets,
    scene,
    executionCancelled,
    setCharacterState,
    setMovementHistory,
    autoReach,
    respectOrder,
  });

  // Fonctions de mouvement
  const walkCharacter = (angle: number) => {
    return moveCharacter(angle, character.displacementUnit, animationPromiseResolverRef);
  };

  const jumpCharacter = (angle: number) => {
    return moveCharacter(angle, character.displacementUnit * 2, animationPromiseResolverRef);
  };


  const isDone = () => {
    setCheckCompletion(true); // Toggle the value to trigger useEffect
  };

  useEffect(() => {
    if (checkCompletion) {
      if (executionCancelled.current) return;

      if (targets.length > 0) {

        let success =
          (mustReachAllTargets && remainingTargetsRef.current.length === 0) ||
          (!mustReachAllTargets && remainingTargetsRef.current.length < targets.length);


        checkResult(success);
        if (success) {
          setCharacterState((prev) => ({
            ...prev,
            state: 'wining',
          }));
        } else {
          if (autoReset) {
            setTimeout(async () => {
              await resetGame();
            }, 500);
          }
        }
      } else {
        // Si aucune cible n'est définie, considérez que l'exercice est terminé avec succès
        checkResult(true);
      }

      // Reset checkCompletion to false after processing
      setCheckCompletion(false);
    }

  }, [checkCompletion]);

  // Exécution du code Blockly
  const handleRun = (): void => {
    executionCancelled.current = false;
    if (workspace) {
      const context = {
        walkCharacter,
        jumpCharacter,
        pauseCharacter,
        resetGame,
        reachTarget,
        isDone,
      };
      executeAsyncCode(workspace, context);
    }
  };


  const changeStartPositionX = (value: number | null) => {
    setCharacterState({ ...character, x: value ? value : 0 })
    setMovementHistory([{ x: value ? value : 0, y: characterState.y }]);
  }
  const changeStartPositionY = (value: number | null) => {
    setCharacterState({ ...character, y: value ? value : 0 })
    setMovementHistory([{ y: value ? value : 0, x: characterState.x }]);
  }
  return (
    <div>

      <Row gutter={[16, 16]} style={{ display: 'flex', flexWrap: 'nowrap' }}>
        <Col flex="auto">
          <BlocklyEditor
            toolbox={toolbox}
            initialBlocksJsonOrXML={initialBlocksJsonOrXML}
            onWorkspaceChange={(ws) => setWorkspace(ws)}
            xmlRef={xmlRef}
            cansave={cansave}
          />
          <div style={{ textAlign: 'center', marginTop: '16px' }}>
            <Space>
              <Button
                type="primary"
                size="large"
                onClick={handleRun}
                disabled={!isRunButtonEnabled}
              >
                {t('button.run')}
              </Button>
            </Space>
          </div>
        </Col>
        <Col style={{ width: `${scene.width}px` }}>
          <IGame
            character={characterState}
            obstacles={obstacles}
            targets={remainingTargets}
            scene={scene}
            movementHistory={drawResult ? movementHistory : []}
            showGrid={showGrid}
            editionMode={editionMode}
            onAnimationComplete={() => {
              if (animationPromiseResolverRef.current) {
                animationPromiseResolverRef.current();
                animationPromiseResolverRef.current = null;
              }
              if (autoReach) {
                reachTargetByXY(characterStateRef.current.x, characterStateRef.current.y);
              }
            }}
          />
          <div style={{ textAlign: 'center', marginTop: '16px' }}>
            <Space>
              <Row gutter={16}>
                {canChangeXY && (
                  <>
                    <Col span={8}>
                      <InputNumber
                        addonBefore="X"
                        min={0}
                        max={scene.width}  // Limite X selon la largeur de la scène
                        value={characterState.x}
                        onChange={(value) => changeStartPositionX(value)}
                        placeholder="Position X"
                        size="large"
                      />
                    </Col>
                    <Col span={8}>
                      <InputNumber
                        addonBefore="Y"
                        min={0}
                        max={scene.height}  // Limite Y selon la hauteur de la scène
                        value={characterState.y}
                        onChange={(value) => changeStartPositionY(value)}
                        placeholder="Position Y"
                        size="large"
                      />
                    </Col>
                  </>
                )}
                {!autoReset && (
                  <>
                    <Col span={8}>
                      <Button type="default" size="large" onClick={() => resetGame()}>
                        {t('button.reset')}
                      </Button>
                    </Col>
                  </>
                )}
              </Row>

            </Space>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default BlocklyGame;
