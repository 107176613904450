import React, { useEffect, useState } from 'react';
import AppLayout from '../../../components/layout/AppLayout';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { TCourse, TChapter } from '../../../models/courses/course';
import { Button, Input, message, Space, Table } from 'antd';
import { SaveOutlined, EyeOutlined, EditOutlined, StopOutlined } from '@ant-design/icons';
import { useCourseApi } from '../../../api/courses/courses';
import type { ColumnsType } from 'antd/es/table';  // Import ColumnsType

const ChapterList: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    const { getChaptersByCourseId, updateChapter, createChapter } = useCourseApi(); // Assumed API for Chapters
    const [chapters, setChapters] = useState<TChapter[]>([]);
    const [loading, setIsLoading] = useState<boolean>(false);
    const [addingId, setAddingId] = useState<number>(0);

    // We assume that the course object is passed via location.state from another component
    const course: TCourse = location.state?.course;

    useEffect(() => {
        if (!course) {
            message.error('No course selected');
            return;
        }

        setIsLoading(true);
        getChaptersByCourseId(course.id)
            .then((resp) => {
                setChapters(resp.data);
            })
            .catch((err) => {
                message.error(err.response.data ?? err.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
        // eslint-disable-next-line
    }, [course]);

    const [editingKey, setEditingKey] = useState<number | null>(null);
    const [editingChapter, setEditingChapter] = useState<TChapter | null>(null);

    // Function to enter edit mode
    const edit = (chapter: TChapter) => {
        setEditingKey(chapter.id);
        setEditingChapter({ ...chapter });
    };

    // Function to handle adding a new chapter
    const handleAddChapter = () => {
        const idRow = addingId - 1;
        const newChapter: TChapter = {
            id: idRow,
            name: '',
            course_id: course.id,  // Attach the course ID
            epics: []
        };

        setAddingId(idRow);
        setEditingKey(idRow);
        setEditingChapter(newChapter);
        setChapters((prev) => [
            newChapter,
            ...(prev || []),
        ]);
    };

    // Save changes
    const save = (id: number) => {
        if (editingChapter) {
            (editingChapter.id > 0 ? updateChapter(editingChapter.id, editingChapter) : createChapter(editingChapter))
                .then((resp) => {
                    const chapterResult: TChapter = resp.data;
                    const updatedChapters = chapters.map((chapter) =>
                        chapter.id === id ? chapterResult : chapter
                    );
                    setChapters(updatedChapters);
                    message.success(t('message.savedSuccessfully'));
                })
                .catch((err) => {
                    message.error(err.response.data ?? err.message);
                    console.error(err);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }

        setEditingKey(null);
    };
    const cancel = () => {
        setEditingKey(null);
        setEditingChapter(null);
    }
    // Show chapter details
    const showDetails = (chapter: TChapter) => {
        navigate('/setup/epic', { state: { chapter } });
    };

    // Explicitly type the columns array
    const columns: ColumnsType<TChapter> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text: string, record: TChapter) =>
                editingKey === record.id ? (
                    <Input
                        value={editingChapter?.name}
                        onChange={(e) => {
                            if (editingChapter) {
                                setEditingChapter({ ...editingChapter, name: e.target.value });
                            }
                        }}
                    />
                ) : (
                    text
                ),
        },
        {
            title: 'Action',
            key: 'actions',
            fixed: 'right' as 'right',  // Fix the column to the right
            width: 180,  // Set fixed width
            render: (_: any, record: TChapter) => (
                <Space size="middle">
                    {editingKey === record.id ? (

                        <>
                            <Button icon={<SaveOutlined />} onClick={() => save(record.id)} />
                            <Button icon={<StopOutlined />} onClick={cancel} />
                        </>
                    ) : (
                        <>
                            <Button icon={<EditOutlined />} onClick={() => edit(record)} />
                            <Button icon={<EyeOutlined />} onClick={() => showDetails(record)} />
                        </>
                    )}
                </Space>
            ),
        },
    ];

    return (
        <AppLayout title={`Course : ${course?.title}`} loading={loading}>
            <Space style={{ marginBottom: 16 }}>
                <Button type="primary" onClick={handleAddChapter}>
                    {t('button.add')}
                </Button>
            </Space>
            <Table<TChapter>  // Add the generic type here
                columns={columns}
                dataSource={chapters}
                rowKey="id"
                loading={loading}
                pagination={{ pageSize: 20 }}  // Pagination with 10 chapters per page
                scroll={{ x: 500 }}  // Enable horizontal scrolling if needed
            />
        </AppLayout>
    );
};

export default ChapterList;
