import { TCharacter } from "../core/type/character"
import penIdle from '../../assets/images/character/pen/pen.png'


export const getMrPen = (x: number, y: number): TCharacter => {
    return {
        x: x,
        y: y,
        state: 'idle',
        width: 50,
        height: 50,
        scale: 1,
        images: {
            idle: penIdle,
            moving: penIdle,
            paused: penIdle,
            colliding: penIdle,
            win: penIdle,
        },
        displacementUnit: 50,
        transition: {
            type: 'tween',
            ease: 'easeInOut', // Ou 'linear' pour une transition constante
            duration: 0.5,
        }
    }
}