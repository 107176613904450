

export const CODIFICATION_TYPE = {
    // blockly game
    TARGET_STATE: "TARGET_STATE",
    CHARACTER_STATE: "CHARACTER_STATE",
    BLOCKLY_CONTENT: "BLOCKLY_FIELD",
    LESSON_METADATA: "LESSON_METADATA",


    USER_TYPE: "USER_TYPE",
}

export const CODIFICATION_TARGET_STATE = {
    idle: "idle",
    reached: "reached"
}

export const CODIFICATION_CHARACTER_STATE = {
    idle: "idle",
    moving: "moving",
    win: "win",
    colliding: "colliding",
}

export const CODIFICATION_LESSON_METADATA = {
    BLOCKLY_XML_TOOLBOX: "BLOCKLY_XML_TOOLBOX",
    SHOW_GRID: "SHOW_GRID",
    DRAW_RESULT: "DRAW_RESULT",
    REACH_ALL_TARGET: "REACH_ALL_TARGET",
    RESPECT_ORDER: "RESPECT_ORDER",
    AUTO_REACH: "AUTO_REACH",
    AUTO_RESET: "AUTO_RESET",
}
