import React, { useEffect, useState } from 'react';
import AppLayout from '../../../components/layout/AppLayout';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { TChapter, TEpic } from '../../../models/courses/course';
import { Button, Input, message, Space, Table } from 'antd';
import { SaveOutlined, EyeOutlined, EditOutlined, StopOutlined } from '@ant-design/icons';
import { useCourseApi } from '../../../api/courses/courses';
import type { ColumnsType } from 'antd/es/table';  // Import ColumnsType

const EpicList: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    const { getEpicsByChapterId, updateEpic, createEpic } = useCourseApi(); // Assumed API for Epics
    const [epics, setEpics] = useState<TEpic[]>([]);
    const [loading, setIsLoading] = useState<boolean>(false);
    const [addingId, setAddingId] = useState<number>(0);

    // We assume that the chapter object is passed via location.state from another component
    const chapter: TChapter = location.state?.chapter;

    useEffect(() => {
        if (!chapter) {
            message.error('No chapter selected');
            // navigate('/chapter'); // Redirect if no chapter is selected
            return;
        }

        setIsLoading(true);
        getEpicsByChapterId(chapter.id)
            .then((resp) => {
                setEpics(resp.data);
            })
            .catch((err) => {
                message.error(err.response.data ?? err.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
        // eslint-disable-next-line
    }, [chapter]);

    const [editingKey, setEditingKey] = useState<number | null>(null);
    const [editingEpic, setEditingEpic] = useState<TEpic | null>(null);

    // Function to enter edit mode
    const edit = (epic: TEpic) => {
        setEditingKey(epic.id);
        setEditingEpic({ ...epic });
    };

    // Function to handle adding a new epic
    const handleAddEpic = () => {
        const idRow = addingId - 1;
        const newEpic: TEpic = {
            id: idRow,
            name: '',
            chapter_id: chapter.id,  // Attach the chapter ID
            lessons: []
        };

        setAddingId(idRow);
        setEditingKey(idRow);
        setEditingEpic(newEpic);
        setEpics((prev) => [
            newEpic,
            ...(prev || []),
        ]);
    };

    // Save changes
    const save = (id: number) => {
        if (editingEpic) {
            (editingEpic.id > 0 ? updateEpic(editingEpic.id, editingEpic) : createEpic(editingEpic))
                .then((resp) => {
                    const epicResult: TEpic = resp.data;
                    const updatedEpics = epics.map((epic) =>
                        epic.id === id ? epicResult : epic
                    );
                    setEpics(updatedEpics);
                    message.success(t('message.savedSuccessfully'));
                })
                .catch((err) => {
                    message.error(err.response.data ?? err.message);
                    console.error(err);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }

        setEditingKey(null);
    };

    // Show epic details
    const showDetails = (epic: TEpic) => {
        navigate('/setup/lesson', { state: { epic } });  // Replace with the correct path
    };
    const cancel = () => {
        setEditingKey(null);
        setEditingEpic(null);
    }
    // Explicitly type the columns array
    const columns: ColumnsType<TEpic> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text: string, record: TEpic) =>
                editingKey === record.id ? (
                    <Input
                        value={editingEpic?.name}
                        onChange={(e) => {
                            if (editingEpic) {
                                setEditingEpic({ ...editingEpic, name: e.target.value });
                            }
                        }}
                    />
                ) : (
                    text
                ),
        },
        {
            title: 'Action',
            key: 'actions',
            fixed: 'right' as 'right',  // Fix the column to the right
            width: 180,  // Set fixed width
            render: (_: any, record: TEpic) => (
                <Space size="middle">
                    {editingKey === record.id ? (

                        <>
                            <Button icon={<SaveOutlined />} onClick={() => save(record.id)} />
                            <Button icon={<StopOutlined />} onClick={cancel} />
                        </>
                    ) : (
                        <>
                            <Button icon={<EditOutlined />} onClick={() => edit(record)} />
                            <Button icon={<EyeOutlined />} onClick={() => showDetails(record)} />
                        </>
                    )}
                </Space>
            ),
        },
    ];

    return (
        <AppLayout title={`Chapter : ${chapter?.name}`} loading={loading}>
            <Space style={{ marginBottom: 16 }}>
                <Button type="primary" onClick={handleAddEpic}>
                    {t('button.add')}
                </Button>
            </Space>
            <Table<TEpic>  // Add the generic type here
                columns={columns}
                dataSource={epics}
                rowKey="id"
                loading={loading}
                pagination={{ pageSize: 20 }}  // Pagination with 10 epics per page
                scroll={{ x: 500 }}  // Enable horizontal scrolling if needed
            />
        </AppLayout>
    );
};

export default EpicList;
