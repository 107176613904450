import React, { useEffect, useState } from 'react';
import AppLayout from '../../../components/layout/AppLayout';
import { useTranslation } from 'react-i18next';
import { Button, Input, message, Select, Space, Table } from 'antd';
import { SaveOutlined, EditOutlined, KeyOutlined, StopOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import type { ColumnsType } from 'antd/es/table';
import { useUserApi } from '../../../api/user/userApp';
import { TUserApp } from '../../../models/user/User';
import { TCodification } from '../../../models/referential/referential';
import { useReferential } from '../../../context/ReferentialContext';
import { CODIFICATION_TYPE } from '../../../constant/codification';

const UserAppList: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { getAllUserApps, createUserApp, updateUserApp } = useUserApi();
    const [userApps, setUserApps] = useState<TUserApp[]>([]);
    const [loading, setIsLoading] = useState<boolean>(false);
    const [addingId, setAddingId] = useState<number>(0);
    const { getCodificationsByCodeType } = useReferential();

    const userTypes: TCodification[] = getCodificationsByCodeType(CODIFICATION_TYPE.USER_TYPE)
    useEffect(() => {
        setIsLoading(true);
        getAllUserApps()
            .then((resp) => {
                setUserApps(resp.data);
            })
            .catch((err) => {
                message.error(err.response.data ?? err.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
        // eslint-disable-next-line
    }, []);

    const [editingKey, setEditingKey] = useState<number | null>(null);
    const [editingUserApp, setEditingUserApp] = useState<TUserApp | null>(null);

    const edit = (userapp: TUserApp) => {
        setEditingKey(userapp.id);
        setEditingUserApp({ ...userapp });
    };

    const handleAddUserApp = () => {
        const idRow = addingId - 1;
        const newUserApp: TUserApp = {
            id: idRow,
            login: '',
            usertype_id: 0,
        };

        setAddingId(idRow);
        setEditingKey(idRow);
        setEditingUserApp(newUserApp);
        setUserApps((prev) => [newUserApp, ...prev]);
    };

    const save = (id: number) => {
        if (editingUserApp) {
            (editingUserApp.id > 0 ? updateUserApp(editingUserApp.id, editingUserApp) : createUserApp(editingUserApp))
                .then((resp) => {
                    const userappResult: TUserApp = resp.data;
                    const updatedUserApps = userApps.map((userapp) =>
                        userapp.id === id ? userappResult : userapp
                    );
                    setUserApps(updatedUserApps);
                    setEditingKey(null);
                    setEditingUserApp(null);
                    message.success(t('message.savedSuccessfully'));
                })
                .catch((err) => {
                    message.error(err.response.data ?? err.message);
                    console.error(err);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }

    };

    const showDetails = (userapp: TUserApp) => {
        navigate('/setup/passwords', { state: { userapp } });
    };
    const cancel = () => {
        setEditingKey(null);
        setEditingUserApp(null);
    }


    const columns: ColumnsType<TUserApp> = [
        {
            title: 'Login',
            dataIndex: 'login',
            key: 'login',
            render: (text: string, record: TUserApp) =>
                editingKey === record.id ? (
                    <Input
                        value={editingUserApp?.login}
                        onChange={(e) => {
                            if (editingUserApp) {
                                setEditingUserApp({ ...editingUserApp, login: e.target.value });
                            }
                        }}
                    />
                ) : (
                    text
                ),
        },
        {
            title: 'User Type',
            dataIndex: 'usertype_id',
            key: 'usertype_id',
            render: (usertype_id: number, record: TUserApp) =>
                editingKey === record.id ? (
                    <Select
                        value={editingUserApp?.usertype_id}
                        onChange={(value: number) => {
                            if (editingUserApp) {
                                setEditingUserApp({ ...editingUserApp, usertype_id: value });
                            }
                        }}
                    >
                        {userTypes.map((type: TCodification) => (
                            <Select.Option key={type.id} value={type.id}>
                                {type.label}
                            </Select.Option>
                        ))}
                    </Select>
                ) : (
                    userTypes.find((type) => type.id === usertype_id)?.label || 'N/A'
                ),
        },
        {
            title: 'Actions',
            key: 'actions',
            fixed: 'right' as 'right',
            width: 180,
            render: (_: any, record: TUserApp) => (
                <Space size="middle">
                    {editingKey === record.id ? (

                        <>
                            <Button icon={<SaveOutlined />} onClick={() => save(record.id)} />
                            <Button icon={<StopOutlined />} onClick={cancel} />
                        </>
                    ) : (
                        <>
                            <Button icon={<EditOutlined />} onClick={() => edit(record)} />
                            <Button icon={<KeyOutlined />} onClick={() => showDetails(record)} />
                        </>
                    )}
                </Space>
            ),
        },
    ];


    return (
        <AppLayout title={t('text.users')} loading={loading}>
            <Space style={{ marginBottom: 16 }}>
                <Button type="primary" onClick={handleAddUserApp}>
                    {t('button.add')}
                </Button>
            </Space>
            <Table<TUserApp>
                columns={columns}
                dataSource={userApps}
                rowKey="id"
                loading={loading}
                pagination={{ pageSize: 20 }}
                scroll={{ x: 1000 }}
            />
        </AppLayout>
    );
};

export default UserAppList;
