import React from 'react';
import { Button, Steps } from 'antd';
import AppLayout from './AppLayout';
import { TLesson } from '../../models/courses/lesson';

interface CourseLayoutProps {
    children: React.ReactNode;
    title: string;
    lessons: TLesson[];
    currentStep: number;
    previous?: () => void;
    next?: () => void;
    goToStep?: (stepIndex: number) => void;
    finishCourse?: () => void;
    isLoading?: boolean;
}

const CourseLayout: React.FC<CourseLayoutProps> = ({
    title,
    children,
    lessons,
    currentStep,
    previous,
    next,
    goToStep,
    finishCourse,
    isLoading = false,
}) => {
    const footerContent = (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {previous ? (
                <Button onClick={previous} type="default">
                    Précédent
                </Button>
            ) : (
                <div />
            )}

            <Steps
                current={currentStep}
                size="small"
                style={{ width: '60%' }}
                onChange={goToStep}
            >
                {lessons.map((lesson, index) => (
                    <Steps.Step key={lesson.id} title={lesson.name} />
                ))}
            </Steps>

            {currentStep === lessons.length - 1 && finishCourse ? (
                <Button type="primary" onClick={finishCourse}>
                    Terminer
                </Button>
            ) : (
                <Button type="primary" onClick={next} disabled={!next}>
                    Suivant
                </Button>
            )}
        </div>
    );

    return (
        <AppLayout title={title} footer={footerContent} loading={isLoading}>
            {children}
        </AppLayout>
    );
};

export default CourseLayout;
