// useApiFunctions.js
import { TPassword, TUserApp } from '../../models/user/User';
import { useApiClient } from '../apiClient';
import { ENDPOINTS_PASSWORDS, ENDPOINTS_USERAPP } from './endpoints';

export const useUserApi = () => {
    const apiClient = useApiClient();

    // UserApps
    const getAllUserApps = () => apiClient.get<TUserApp[]>(ENDPOINTS_USERAPP.GET_ALL);
    const getUserAppById = (id: number) => apiClient.get<TUserApp>(ENDPOINTS_USERAPP.GET_BY_ID(id));
    const createUserApp = (data: TUserApp) => apiClient.post(ENDPOINTS_USERAPP.POST, data);
    const updateUserApp = (id: number, data: TUserApp) => apiClient.put(ENDPOINTS_USERAPP.PUT(id), data);
    const deleteUserApp = (id: number) => apiClient.delete(ENDPOINTS_USERAPP.DELETE(id));

    // Passwords 
    const getPasswordsByUserAppId = (id: number) => apiClient.get<TPassword[]>(ENDPOINTS_PASSWORDS.GET_BY_IDUSERAPP(id));
    const createPassword = (data: TPassword) => apiClient.post(ENDPOINTS_PASSWORDS.POST, data);
    const updatePassword = (id: number, data: TPassword) => apiClient.put(ENDPOINTS_PASSWORDS.PUT(id), data);
    const deletePassword = (id: number) => apiClient.delete(ENDPOINTS_PASSWORDS.DELETE(id));


    return {

        getAllUserApps,
        getUserAppById,
        createUserApp,
        updateUserApp,
        deleteUserApp,

        createPassword,
        updatePassword,
        deletePassword,
        getPasswordsByUserAppId,
    };
};
