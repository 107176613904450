import React, { useEffect, useState } from 'react';
import { Layout, Typography, Button, Select, Menu, Dropdown, Avatar } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';  // Importer i18next
import MAFlag from '../../assets/images/flag/morocco.png';
import FRFlag from '../../assets/images/flag/france.png';
import USFlag from '../../assets/images/flag/usa.png';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const { Header } = Layout;
const { Title } = Typography;
const { Option } = Select;

interface NavbarProps {
    title: string;
    onToggleSidebar: () => void;
    isSidebarCollapsed: boolean;
}

const Navbar: React.FC<NavbarProps> = ({ title, onToggleSidebar, isSidebarCollapsed }) => {
    const navigate = useNavigate();
    const { i18n } = useTranslation();
    const { logout, getTimeRemaining } = useAuth();

    const timeRemaining: number = getTimeRemaining();
    const [timeLeft, setTimeLeft] = useState<number>(timeRemaining);

    // timer
    useEffect(() => {
        // Si timeRemaining est -1, on ne lance pas le minuteur
        if (timeRemaining === -1 || timeRemaining > 5400) {
            return;
        }

        // Fonction qui décrémente le temps restant toutes les secondes
        const timerId = setInterval(() => {
            setTimeLeft((prevTime) => {
                if (prevTime <= 1) {
                    clearInterval(timerId); // Arrêter le minuteur quand il atteint 0
                    logout()
                    navigate('/'); // Rediriger vers la page de login
                    return 0; // Retourner 0 pour s'assurer que le temps est bien à 0
                }
                return prevTime - 1; // Décrémenter le temps restant
            });
        }, 1000); // Mise à jour toutes les 1000 ms (1 seconde)

        // Nettoyage du minuteur lorsque le composant est démonté
        return () => clearInterval(timerId);
    }, [timeRemaining, navigate]);

    // Fonction pour changer la langue
    const handleLanguageChange = (value: string) => {
        i18n.changeLanguage(value);
    };

    const handleLogout = () => {
        logout();
        navigate('/');
    }
    // Menu déroulant pour le profil
    const profileMenu = {
        items: [
            {
                key: 'logout',
                label: (
                    <a onClick={handleLogout}>
                        Déconnexion
                    </a>
                ),
            },
        ],
    };

    return (
        <Header
            style={{
                background: '#fff',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0 16px',
                boxShadow: '0 2px 2px rgba(0, 0, 0, 0.1)',
                zIndex: 2,
            }}
        >
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Button type="text" onClick={onToggleSidebar} style={{ marginRight: '16px' }}>
                    {isSidebarCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                </Button>
                <Title level={4} style={{ margin: 0 }}>{title}</Title>
            </div>

            <div style={{ display: 'flex', alignItems: 'center' }}>
                {timeLeft > 0 && (
                    <div style={{ marginRight: '16px', fontWeight: 'bold' }}>
                        Temps restant: {new Date(timeLeft * 1000).toISOString().slice(11, 19)}
                    </div>
                )}

                {/* Select pour changer la langue */}
                <Select
                    defaultValue={i18n.language}  // Définit la langue par défaut
                    style={{ width: 120 }}
                    onChange={handleLanguageChange}
                >
                    <Option value="fr">
                        <img src={FRFlag} alt="Français" style={{ width: '16px', marginRight: '8px' }} />
                        Français
                    </Option>
                    <Option value="en">
                        <img src={USFlag} alt="English" style={{ width: '16px', marginRight: '8px' }} />
                        English
                    </Option>
                    <Option value="ar">
                        <img src={MAFlag} alt="Arabe" style={{ width: '16px', marginRight: '8px' }} />
                        العربية
                    </Option>
                </Select>

                {/* Dropdown pour le profil avec un avatar */}
                <Dropdown menu={profileMenu} trigger={['click']} placement="bottomRight">
                    <Avatar style={{ marginLeft: 16, cursor: 'pointer' }} icon={<UserOutlined />} />
                </Dropdown>
            </div>
        </Header>
    );
};

export default Navbar;
