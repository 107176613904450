// components/CourseCard.tsx
import React from 'react';
import { Card, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { TCourse } from '../../models/courses/course';
import { useTranslation } from 'react-i18next';
import { getImagePath } from '../../utils/format/valueFormat';



interface CourseCardProps {
    course: TCourse;
}

const CourseCard: React.FC<CourseCardProps> = ({ course }) => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const goToDetails = () => {
        navigate(`/course-details/${course.id}`);
    };

    return (
        <Card
            hoverable
            cover={<img alt={course.title} src={getImagePath(course.image)} />}
            style={{ margin: '16px' }}
        >
            <Card.Meta title={course.title} description={course.description} />
            <p>{t("text.agefrom", { age_min: (course.min_age ?? "-") })} {t("text.ageto", { age_max: (course.max_age ?? "-") })}</p>
            <Button type="primary" onClick={goToDetails}>Voir les détails</Button>
        </Card>
    );
};

export default CourseCard;
