import axios, { InternalAxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import config from '../config/config.json'; // Import du fichier JSON
import { useAuth } from '../context/AuthContext';

export const useApiClient = () => {
    const handleError = useHandleError();
    const { getToken } = useAuth();

    // Utilisation des valeurs de configuration directement depuis config.json
    const apiClient = axios.create({
        baseURL: config.api.baseURL + "api/", // Utilisation de l'URL de l'API depuis config.json
        timeout: config.api.timeout, // Utilisation du timeout depuis config.json
    });

    // Intercepteur de requêtes pour ajouter le token d'autorisation
    apiClient.interceptors.request.use(
        (config: InternalAxiosRequestConfig) => {
            const token = getToken(); // Récupération du token à partir du contexte Auth
            if (token) {
                config.headers.Authorization = `Bearer ${token}`; // Ajout du token à l'en-tête Authorization
            }
            return config;
        },
        (error: any) => {
            return Promise.reject(error);
        }
    );

    // Intercepteur de réponses pour gérer les types de réponses
    apiClient.interceptors.response.use(
        (response: AxiosResponse) => {
            return response;
        },
        (error: any) => {
            handleError(error);
            return Promise.reject(error);
        }
    );

    return apiClient;
};

const useHandleError = () => {
    return (error: AxiosError | any): void => {
        if (error.response) {
            if (error.response.status === 401) {
                // navigate('/auth'); // Redirection en cas d'erreur 401
            }
        } else {
            // Gérer d'autres erreurs ici
        }
    };
};
