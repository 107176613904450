import React from 'react';
import { Input, Select, Space } from 'antd';
import { TCharacter } from '../../../../models/objects/objects';
import { TLesson } from '../../../../models/courses/lesson';
import { getImagePath } from '../../../../utils/format/valueFormat';

const { Option } = Select;

interface CharacterProps {
    characters: TCharacter[];
    editingLesson: TLesson | undefined;
    setEditingLesson: React.Dispatch<React.SetStateAction<TLesson | undefined>>;
}

const Character: React.FC<CharacterProps> = ({ characters, editingLesson, setEditingLesson }) => {

    const handleChange = (Id: number) => {
        const selected = characters.find(e => e.id === Id)
        if (editingLesson && selected) {
            setEditingLesson({
                ...editingLesson,
                character: {
                    ...(editingLesson.character || { id: 0, lessonId: 0, x: 0, y: 0, width: 50, height: 50, scale: 1, displacementUnit: 50 }),
                    characterId: selected.id,
                    character: selected
                }
            });
        }
    };

    const handleInputChange = (name: string, value: string | number) => {
        if (editingLesson) {
            setEditingLesson({
                ...editingLesson,
                character: {
                    ...(editingLesson.character || { id: 0, characterId: 0, lessonId: 0, x: 0, y: 0, width: 50, height: 50, scale: 1, displacementUnit: 50, character: {} as TCharacter }),
                    [name]: value
                }
            });
        }
    }

    return (
        <Space direction="vertical">
            <Select
                placeholder={'Character'}
                style={{ width: '100%' }}
                onChange={handleChange}
                value={editingLesson?.character?.character?.id}
            >
                {characters.map(character => (
                    <Option key={character.id} value={character.id}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img
                                src={getImagePath(character.images[0].img)}
                                alt={character.name}
                                style={{ width: '30px', height: '30px', marginRight: '10px' }}
                            />
                            <span>{character.name}</span>
                        </div>
                    </Option>
                ))}
            </Select>
            <Input
                name='x'
                addonBefore="x"
                value={editingLesson?.character?.x}
                onChange={(e) => {
                    if (editingLesson) {
                        const newvalue = parseFloat(e.target.value) || 0;
                        handleInputChange(e.target.name, newvalue)
                    }
                }}
            />
            <Input
                name='y'
                addonBefore="y"
                value={editingLesson?.character?.y}
                onChange={(e) => {
                    if (editingLesson) {
                        const newvalue = parseFloat(e.target.value) || 0;
                        handleInputChange(e.target.name, newvalue)
                    }
                }}
            />
            <Input
                name='width'
                addonBefore="width"
                value={editingLesson?.character?.width}
                onChange={(e) => {
                    if (editingLesson) {
                        const newvalue = parseFloat(e.target.value) || 0;
                        handleInputChange(e.target.name, newvalue)
                    }
                }}
            />
            <Input
                name='height'
                addonBefore="height"
                value={editingLesson?.character?.height}
                onChange={(e) => {
                    if (editingLesson) {
                        const newvalue = parseFloat(e.target.value) || 0;
                        handleInputChange(e.target.name, newvalue)
                    }
                }}
            />
            <Input
                name='scale'
                addonBefore="scale"
                value={editingLesson?.character?.scale}
                onChange={(e) => {
                    if (editingLesson) {
                        const newvalue = parseFloat(e.target.value) || 0;
                        handleInputChange(e.target.name, newvalue)
                    }
                }}
            />
            <Input
                name='displacementUnit'
                addonBefore="displacementUnit"
                value={editingLesson?.character?.displacementUnit}
                onChange={(e) => {
                    if (editingLesson) {
                        const newvalue = parseFloat(e.target.value) || 50;
                        handleInputChange(e.target.name, newvalue)
                    }
                }}
            />
        </Space>
    );
};

export default Character;
