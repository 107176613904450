import React from 'react';
import CourseDetails from '../pages/course/CourseDetails';
import CourseProgress from '../pages/course/CourseProgress';
import ProtectedRoute from './ProtectedRoute';  // Utiliser le composant ProtectedRoute
import Program from '../pages/program/Program';
import CourseList from '../pages/admin/course/CourseList';
import ChapterList from '../pages/admin/course/ChapterList';
import EpicList from '../pages/admin/course/EpicList';
import LessonList from '../pages/admin/course/LessonList';
import LessonDetails from '../pages/admin/course/lessonDetails/LessonDetails';
import Login from '../pages/auth/auth/Login';
import { useAuth } from '../context/AuthContext';  // Importer le AuthContext pour la redirection
import { Navigate } from 'react-router-dom';
import UserAppList from '../pages/admin/user/UserAppList';
import PasswordList from '../pages/admin/user/PasswordList';
import BlocklyObjects from '../pages/admin/blocklyObjects/BlocklyObjects';


// Nouveau composant pour gérer la redirection selon l'état de connexion
const AuthRedirect: React.FC = () => {
  const { getToken } = useAuth();

  if (getToken()) {
    // Si l'utilisateur est connecté, rediriger vers la page des programmes
    return <Navigate to="/program" />;
  }

  // Si l'utilisateur n'est pas connecté, afficher la page de connexion
  return <Login />;
};


const routes = [
  {
    path: '/',
    element: (
      // Vérifier si l'utilisateur est connecté
      <AuthRedirect />  // Nouveau composant pour gérer la redirection
    ),
    isProtected: false,
  },
  {
    path: '/program',
    element: <ProtectedRoute element={<Program />} />,
    isProtected: true,
  },
  {
    path: '/course-details/:courseId',
    element: <ProtectedRoute element={<CourseDetails />} />,
    isProtected: true,  // Route protégée
  },
  {
    path: '/course-progression/:lessonId',
    element: <ProtectedRoute element={<CourseProgress />} />,
    isProtected: true,  // Route protégée
  },
  {
    path: '/setup/course',
    element: <ProtectedRoute element={<CourseList />} />,
    isProtected: true,  // Route protégée
  },
  {
    path: '/setup/chapter',
    element: <ProtectedRoute element={<ChapterList />} />,
    isProtected: true,  // Route protégée
  },
  {
    path: '/setup/epic',
    element: <ProtectedRoute element={<EpicList />} />,
    isProtected: true,  // Route protégée
  },
  {
    path: '/setup/lesson',
    element: <ProtectedRoute element={<LessonList />} />,
    isProtected: true,  // Route protégée
  },
  {
    path: '/setup/lesson-details',
    element: <ProtectedRoute element={<LessonDetails />} />,
    isProtected: true,  // Route protégée
  },
  {
    path: '/setup/users',
    element: <ProtectedRoute element={<UserAppList />} />,
    isProtected: true,  // Route protégée
  },
  {
    path: '/setup/passwords',
    element: <ProtectedRoute element={<PasswordList />} />,
    isProtected: true,  // Route protégée
  },
  {
    path: '/setup/blockly-objects',
    element: <ProtectedRoute element={<BlocklyObjects />} />,
    isProtected: true,  // Route protégée
  },
];

export default routes;
