import React, { useEffect, useState } from 'react';
import AppLayout from '../../../components/layout/AppLayout';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { TEpic } from '../../../models/courses/course';
import { Button, Input, message, Space, Table } from 'antd';
import { SaveOutlined, EyeOutlined, EditOutlined, CopyOutlined, StopOutlined } from '@ant-design/icons';
import { useCourseApi } from '../../../api/courses/courses';  // Assumed API for lessons
import { TLesson } from '../../../models/courses/lesson';
import type { ColumnsType } from 'antd/es/table';  // Import ColumnsType

const LessonList: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    const { getLessonsByEpicId, updateLesson, createLesson, duplicateLesson } = useCourseApi(); // Assumed API for Lessons
    const [lessons, setLessons] = useState<TLesson[]>([]);
    const [loading, setIsLoading] = useState<boolean>(false);
    const [addingId, setAddingId] = useState<number>(0);

    // We assume that the epic object is passed via location.state from another component
    const epic: TEpic = location.state?.epic;

    useEffect(() => {
        if (!epic) {
            message.error('No epic selected');
            navigate('/epic'); // Redirect if no epic is selected
            return;
        }

        setIsLoading(true);
        getLessonsByEpicId(epic.id)
            .then((resp) => {
                setLessons(resp.data);
            })
            .catch((err) => {
                message.error(err.response.data ?? err.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
        // eslint-disable-next-line
    }, [epic]);

    const [editingKey, setEditingKey] = useState<number | null>(null);
    const [editingLesson, setEditingLesson] = useState<TLesson | null>(null);

    // Function to enter edit mode
    const edit = (lesson: TLesson) => {
        setEditingKey(lesson.id);
        setEditingLesson({ ...lesson });
    };

    // Function to handle adding a new lesson
    const handleAddLesson = () => {
        const idRow = addingId - 1;
        const newLesson: TLesson = {
            id: idRow,
            name: '',
            statement: '',
            lesson_order: 0,
            exercise_component: '',
            epic_id: epic.id,  // Attach the epic ID
        };

        setAddingId(idRow);
        setEditingKey(idRow);
        setEditingLesson(newLesson);
        setLessons((prev) => [
            newLesson,
            ...(prev || []),
        ]);
    };

    // Save changes
    const save = (id: number) => {
        if (editingLesson) {
            (editingLesson.id > 0 ? updateLesson(editingLesson.id, editingLesson) : createLesson(editingLesson))
                .then((resp) => {
                    const lessonResult: TLesson = resp.data;
                    const updatedLessons = lessons.map((lesson) =>
                        lesson.id === id ? lessonResult : lesson
                    );
                    setLessons(updatedLessons);
                    message.success(t('message.savedSuccessfully'));
                })
                .catch((err) => {
                    message.error(err.response.data ?? err.message);
                    console.error(err);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }

        setEditingKey(null);
    };

    // Duplicate lesson
    const duplicate = (id: number) => {
        duplicateLesson(id)
            .then((resp) => {
                const lessonResult: TLesson = resp.data;

                setLessons([...lessons, lessonResult]);
                message.success(t('message.savedSuccessfully'));
            })
            .catch((err) => {
                message.error(err.response.data ?? err.message);
                console.error(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const cancel = () => {
        setEditingKey(null);
        setEditingLesson(null);
    }
    // Show lesson details
    const showDetails = (lesson: TLesson) => {
        navigate('/setup/lesson-details', { state: { lesson, epic } });  // Replace with the correct path
    };

    // Explicitly type the columns array
    const columns: ColumnsType<TLesson> = [
        {
            title: 'Nom',
            dataIndex: 'name',
            key: 'name',
            render: (text: string, record: TLesson) =>
                editingKey === record.id ? (
                    <Input
                        value={editingLesson?.name}
                        onChange={(e) => {
                            if (editingLesson) {
                                setEditingLesson({ ...editingLesson, name: e.target.value });
                            }
                        }}
                    />
                ) : (
                    text
                ),
        },
        {
            title: 'Enoncé',
            dataIndex: 'statement',
            key: 'statement',
            render: (text: string, record: TLesson) =>
                editingKey === record.id ? (
                    <Input
                        value={editingLesson?.statement}
                        onChange={(e) => {
                            if (editingLesson) {
                                setEditingLesson({ ...editingLesson, statement: e.target.value });
                            }
                        }}
                    />
                ) : (
                    text
                ),
        },
        {
            title: 'Ordre',
            dataIndex: 'lesson_order',
            key: 'lesson_order',
            render: (text: number, record: TLesson) =>
                editingKey === record.id ? (
                    <Input
                        type="number"
                        value={editingLesson?.lesson_order}
                        onChange={(e) => {
                            if (editingLesson) {
                                setEditingLesson({ ...editingLesson, lesson_order: parseInt(e.target.value) });
                            }
                        }}
                    />
                ) : (
                    text
                ),
        },
        {
            title: 'Action',
            key: 'actions',
            fixed: 'right' as 'right',  // Use type assertion to fix the type error
            width: 180,  // Set fixed width
            render: (_: any, record: TLesson) => (
                <Space size="middle">
                    {editingKey === record.id ? (
                        <>
                            <Button icon={<SaveOutlined />} onClick={() => save(record.id)} />
                            <Button icon={<StopOutlined />} onClick={cancel} />
                        </>
                    ) : (
                        <>
                            <Button icon={<EditOutlined />} onClick={() => edit(record)} />
                            <Button icon={<EyeOutlined />} onClick={() => showDetails(record)} />
                            <Button icon={<CopyOutlined />} onClick={() => duplicate(record.id)} />
                        </>
                    )}
                </Space>
            ),
        },
    ];

    return (
        <AppLayout title={`Epic : ${epic?.name}`} loading={loading}>
            <Space style={{ marginBottom: 16 }}>
                <Button type="primary" onClick={handleAddLesson}>
                    {t('button.add')}
                </Button>
            </Space>
            <Table<TLesson>  // Add the generic type here
                columns={columns}
                dataSource={lessons}
                rowKey="id"
                loading={loading}
                pagination={{ pageSize: 20 }}  // Pagination with 10 lessons per page
                scroll={{ x: 800 }}  // Enable horizontal scrolling if needed
            />
        </AppLayout>
    );
};

export default LessonList;
