// src/game/GameLogic.ts

import { TCharacter } from '../type/character';
import { TObstacle } from '../type/obstacle';
import { TTarget } from '../type/target';
import { TScene } from '../type/scene';
import { message } from 'antd';
import { flushSync } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { MutableRefObject, useEffect, useRef } from 'react';

interface GameLogicProps {
    character: TCharacter;
    characterState: TCharacter;
    characterStateRef: React.MutableRefObject<TCharacter>;
    obstacles: TObstacle[];
    initialTargets: TTarget[];
    remainingTargets: TTarget[];
    setRemainingTargets: React.Dispatch<React.SetStateAction<TTarget[]>>;
    scene: TScene;
    executionCancelled: React.MutableRefObject<boolean>;
    setCharacterState: React.Dispatch<React.SetStateAction<TCharacter>>;
    setMovementHistory: React.Dispatch<React.SetStateAction<{ x: number; y: number }[]>>;
    autoReach: boolean;
    respectOrder: boolean;
}

export const useGameLogic = ({
    character,
    characterState,
    characterStateRef,
    obstacles,
    initialTargets,
    remainingTargets,
    setRemainingTargets,
    scene,
    executionCancelled,
    setCharacterState,
    setMovementHistory,
    autoReach,
    respectOrder,

}: GameLogicProps) => {
    const { t } = useTranslation();


    // probleme de mise à jours lors d'une modification du parent
    useEffect(() => {
        setCharacterState(character)
    }, [character]);

    // Fonction de vérification des collisions
    const checkCollision = (
        nextX: number,
        nextY: number,
        prevCharacterState: TCharacter
    ) => {
        let isColliding = false;
        let adjustedX = nextX;
        let adjustedY = nextY;

        // Vérifier les collisions avec les obstacles
        for (const obstacle of obstacles) {
            if (
                nextX < obstacle.x + obstacle.width &&
                nextX + prevCharacterState.width > obstacle.x &&
                nextY < obstacle.y + obstacle.height &&
                nextY + prevCharacterState.height > obstacle.y
            ) {
                isColliding = true;

                // Ajuster la position en fonction du côté de la collision
                const fromLeft = prevCharacterState.x + prevCharacterState.width <= obstacle.x;
                const fromRight = prevCharacterState.x >= obstacle.x + obstacle.width;
                const fromTop = prevCharacterState.y + prevCharacterState.height <= obstacle.y;
                const fromBottom = prevCharacterState.y >= obstacle.y + obstacle.height;

                if (fromLeft) {
                    adjustedX = obstacle.x - prevCharacterState.width;
                } else if (fromRight) {
                    adjustedX = obstacle.x + obstacle.width;
                } else if (fromTop) {
                    adjustedY = obstacle.y - prevCharacterState.height;
                } else if (fromBottom) {
                    adjustedY = obstacle.y + obstacle.height;
                }
                break;
            }
        }

        // Vérifier les collisions avec les limites de la scène
        if (nextX < 0) {
            adjustedX = 0;
            isColliding = true;
        } else if (nextX + prevCharacterState.width > scene.width) {
            adjustedX = scene.width - prevCharacterState.width;
            isColliding = true;
        }

        if (nextY < 0) {
            adjustedY = 0;
            isColliding = true;
        } else if (nextY + prevCharacterState.height > scene.height) {
            adjustedY = scene.height - prevCharacterState.height;
            isColliding = true;
        }

        return { isColliding, adjustedX, adjustedY };
    };

    // Fonction pour atteindre les cibles
    const reachTargetByXY = (nextX: number, nextY: number) => {
        const characterState = characterStateRef.current;
        // Trouver la cible atteinte
        const actualTarget = remainingTargets.find((target) => {
            return (
                nextX < target.x + target.width &&
                nextX + characterState.width > target.x &&
                nextY < target.y + target.height &&
                nextY + characterState.height > target.y
            );
        });

        if (actualTarget) {

            if (respectOrder) {
                if (actualTarget === remainingTargets[0]) {
                    updateOrRemoveTarget(actualTarget);
                } else {
                    executionCancelled.current = true;
                    message.error(t("message.wrongOrder"));
                    setTimeout(async () => {
                        await resetGame();
                    }, 1000);
                }
            } else {
                updateOrRemoveTarget(actualTarget);
            }
        }
    };

    // Mettre à jour ou retirer une cible atteinte
    const updateOrRemoveTarget = (target: TTarget) => {
        const newQuantity = target.quantity - 1;

        if (newQuantity > 0) {
            setRemainingTargets((prevTargets) =>
                prevTargets.map((t) => (t === target ? { ...t, quantity: newQuantity } : t))
            );
        } else {
            setRemainingTargets((prevTargets) => prevTargets.filter((t) => t !== target));
        }
    };

    const adjustDistanceForAngle = (angle: number, baseDistance: number): number => {
        // Normaliser l'angle entre 0 et 360 degrés
        const normalizedAngle = ((angle % 360) + 360) % 360;

        // Angles diagonaux (45°, 135°, 225°, 315°)
        const diagonalAngles = [45, 135, 225, 315];

        if (diagonalAngles.includes(normalizedAngle)) {
            // Multiplier la distance par √2 pour les déplacements diagonaux
            return baseDistance * Math.SQRT2;
        } else {
            // Garder la distance de base pour les autres angles
            return baseDistance;
        }
    };


    // Fonction pour déplacer le personnage
    const moveCharacter = async (
        angle: number,
        distance: number,
        animationPromiseResolverRef: MutableRefObject<(() => void) | null>
    ): Promise<void> => {
        if (executionCancelled.current) {
            // Rejeter la Promise pour interrompre l'exécution
            return Promise.resolve();
        }

        const adjustedDistance = adjustDistanceForAngle(angle, distance);

        return new Promise<void>((resolve) => {
            // Stocker le résolveur pour le rappeler lorsque l'animation est terminée
            animationPromiseResolverRef.current = resolve;

            // Utiliser flushSync pour forcer la mise à jour immédiate de l'état
            setCharacterState((prev) => {
                const radians = (angle * Math.PI) / 180;
                const deltaX = adjustedDistance * Math.cos(radians);
                const deltaY = adjustedDistance * Math.sin(radians);

                // Calcul des positions suivantes
                const nextX = prev.x + deltaX;
                const nextY = prev.y + deltaY;

                const { isColliding, adjustedX, adjustedY } = checkCollision(
                    nextX,
                    nextY,
                    prev
                );

                const finalX = isColliding ? adjustedX : nextX;
                const finalY = isColliding ? adjustedY : nextY;

                setMovementHistory((prev) => [...prev, { x: finalX, y: finalY }]);
                return {
                    ...prev,
                    x: finalX,
                    y: finalY,
                    state: isColliding ? 'colliding' : 'moving',
                    scale: deltaX >= 0 ? 1 : -1,
                };
            });
        });
    };

    // Fonction pour réinitialiser le jeu
    const resetGame = async (): Promise<void> => {
        executionCancelled.current = true; // Annuler toute exécution en cours

        return new Promise<void>((resolve) => {
            // Utiliser flushSync pour forcer la mise à jour immédiate de l'état
            flushSync(() => {
                // Remettre l'état du personnage et les autres états
                setCharacterState(character);
                setRemainingTargets(initialTargets);
                setMovementHistory([{ x: character.x, y: character.y }]);
            });
            // Appeler resolve() après la réinitialisation
            resolve();
        });
    };


    const handleCollision = async (characterState: TCharacter): Promise<void> => {
        if (characterState.state === 'colliding') {
            // Afficher un message d'erreur en cas de collision
            message.error(t('message.collisionOrOutOfBounds'));

            // Annuler toute exécution en cours
            executionCancelled.current = true;

            // Attendre la réinitialisation complète du jeu
            await new Promise<void>((resolve) => {
                setTimeout(async () => {
                    await resetGame();
                    resolve();
                }, 1000);
            });
        }
    };

    // manual reach
    const reachTarget = async () => {
        reachTargetByXY(characterStateRef.current.x, characterStateRef.current.y)
    }


    useEffect(() => {
        handleCollision(characterState);
        // eslint-disable-next-line
    }, [characterState.state]);

    return {
        moveCharacter,
        resetGame,
        reachTargetByXY,
        handleCollision,
        reachTarget,
    };
};
